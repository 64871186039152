import { createSelector } from 'reselect';
import { urls as autocompleteUrls } from 'Reducers/autocomplete';
import { paramsToQueryString } from 'Helpers/url';
import { getSelectedLangCode } from 'Selectors/config';
import { getType } from 'Reducers/helpers/typeModifier';

/**
 * Get search sugeestion collection object
 */
export const getSearchSuggestionsSelectorCollection = createSelector(
    state => state.autocomplete,
    (_, { searchTerm }) => searchTerm,
    (_, { config }) => config && config.get('autoComplete'),
    getSelectedLangCode,
    (autocomplete, searchTerm, autoCompleteConfig, lang) => {
        const params = {
            q: searchTerm,
            site_code: autoCompleteConfig.siteCode,
            lang
        };

        return autocomplete.collections[getType('SUGGESTIONS', `${autocompleteUrls.getSearchSuggestions}?${paramsToQueryString(params)}`)];
    }
);

/**
 * Returns search suggestion from state
 * @param state
 * @param searchTerm
 * @param config
 */
export const getSearchSuggestionsSelector = createSelector(
    getSearchSuggestionsSelectorCollection,
    (_, { config }) => config.get('autoComplete'),
    (collection, autoCompleteConfig) => {
        const suggestions = (collection && collection.suggestions) || [];

        return suggestions
            .filter(item => item.title)
            .slice(0, autoCompleteConfig.suggestionsLimit)
            .sort((a, b) => {
                if (a.type && b.type) {
                    return a.type > b.type ? -1 : 1;
                }
                else if (a.type) {
                    return -1;
                }
                else if (b.type) {
                    return 1;
                }

                return a.title < b.title ? -1 : 1;
            });
    }
);

/**
 * Returns search suggestion request id
 * @param state
 * @param searchTerm
 * @param config
 */
export const getAutocompleteRequestMetaData = createSelector(
    getSearchSuggestionsSelectorCollection,
    collection => {
        const { spell = '', version = '', 'x-request-id': xRequestId = '' } = collection || {};

        return {
            spell,
            version,
            xRequestId
        };
    }
);
