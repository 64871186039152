import { fileNames } from 'Constants/imageUrls';
import {
    DESKTOP_OLX_LOGO_SIZES,
    DESKTOP_AUTOS_LOGO_SIZES,
    MOBILE_OLX_LOGO_SIZES,
    MOBILE_AUTOS_LOGO_SIZES
} from 'Constants/header';
import { isMobile } from 'Helpers/devices';
import { THEMES } from 'Constants/bundles';

export const getLogoData = (theme = '', showAutosLogo = false, staticAssetPath = '', enableNewIndonesiaBranding = false) => {
    if (theme === THEMES.OTOPLUS) {
        return {
            logoUrl: `${ staticAssetPath }${ isMobile ? fileNames.OTOPLUS_MOBILE_LOGO : fileNames.OTOPLUS_DESKTOP_LOGO }`,
            logoSize: DESKTOP_OLX_LOGO_SIZES.HEIGHT,
            logoClass: isMobile ? 'brandLogo' : '',
            logoWidth: isMobile ? MOBILE_OLX_LOGO_SIZES.WIDTH : DESKTOP_OLX_LOGO_SIZES.WIDTH,
            logoHeight: isMobile ? MOBILE_OLX_LOGO_SIZES.HEIGHT : DESKTOP_OLX_LOGO_SIZES.HEIGHT
        };
    }
    if (theme === THEMES.LETGO) {
        return {
            logoUrl: `${ staticAssetPath }${ fileNames.LOGO }`,
            logoSize: DESKTOP_OLX_LOGO_SIZES.HEIGHT,
            logoClass: isMobile ? 'brandLogo' : '',
            logoWidth: isMobile ? MOBILE_OLX_LOGO_SIZES.WIDTH : DESKTOP_OLX_LOGO_SIZES.WIDTH,
            logoHeight: isMobile ? MOBILE_OLX_LOGO_SIZES.HEIGHT : DESKTOP_OLX_LOGO_SIZES.HEIGHT
        };
    }
    if (theme === THEMES['321SPRZEDANE']) {
        return {
            logoUrl: `${ staticAssetPath }${fileNames['321SPRZEDANE_LOGO'] }`,
            logoSize: DESKTOP_OLX_LOGO_SIZES.HEIGHT,
            logoClass: isMobile ? 'brandLogo' : '',
            logoWidth: isMobile ? MOBILE_OLX_LOGO_SIZES.WIDTH : DESKTOP_OLX_LOGO_SIZES.WIDTH,
            logoHeight: isMobile ? MOBILE_OLX_LOGO_SIZES.HEIGHT : DESKTOP_OLX_LOGO_SIZES.HEIGHT
        };
    }
    else if (showAutosLogo) {
        return {
            logoUrl: `${ staticAssetPath }${ fileNames.OLX_AUTOS_TEAL_LOGO }`,
            logoSize: DESKTOP_AUTOS_LOGO_SIZES.HEIGHT,
            logoClass: '',
            logoWidth: isMobile ? MOBILE_AUTOS_LOGO_SIZES.WIDTH : DESKTOP_AUTOS_LOGO_SIZES.WIDTH,
            logoHeight: isMobile ? MOBILE_AUTOS_LOGO_SIZES.HEIGHT : DESKTOP_AUTOS_LOGO_SIZES.HEIGHT
        };
    }
    else if (enableNewIndonesiaBranding) {
        return {
            logoUrl: `${ staticAssetPath }${ fileNames.OLXID_LOGO }`,
            logoSize: DESKTOP_OLX_LOGO_SIZES.HEIGHT,
            logoClass: 'newLogo',
            logoWidth: isMobile ? MOBILE_OLX_LOGO_SIZES.WIDTH : DESKTOP_OLX_LOGO_SIZES.WIDTH,
            logoHeight: isMobile ? MOBILE_OLX_LOGO_SIZES.HEIGHT : DESKTOP_OLX_LOGO_SIZES.HEIGHT
        };
    }

    return {
        logoUrl: '',
        logoSize: DESKTOP_OLX_LOGO_SIZES.HEIGHT,
        logoClass: '',
        logoWidth: DESKTOP_OLX_LOGO_SIZES.WIDTH,
        logoHeight: DESKTOP_OLX_LOGO_SIZES.HEIGHT
    };
};
