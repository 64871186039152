import { createSelector } from 'reselect';

const completeElementData = (collection, elements) => {
    return collection.map(id => elements[id]);
};

export const bundlesSelector = createSelector(
    ({ bundles }) => bundles && bundles.collections,
    ({ bundles }) => bundles && bundles.elements,
    (collections, elements) => {
        const bundles = {};

        if (collections) {
            Object.keys(collections).reduce((previous, key) => {
                bundles[key] = { ...collections[key] };
                bundles[key].items = completeElementData(collections[key].items, elements);
                return previous;
            }, { });
        }
        return bundles;
    }
);

export const bundleStatus = createSelector(
    ({ bundles }) => bundles && bundles.isError,
    ({ bundles }) => bundles && bundles.isFetching,
    ({ bundles }) => bundles && bundles.lastCollectionUrl,
    ({ bundles }) => bundles && bundles.updateBundles,
    (isError, isFetching, lastCollectionUrl, updateBundles) => {
        return {
            isError,
            isFetching,
            lastCollectionUrl,
            updateBundles
        };
    }
);

