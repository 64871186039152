import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withRouter from 'HOCs/withRouter';
import withTrack from 'HOCs/withTrack/withTrack';
import { isEmpty } from 'Helpers/objects';
import NotificationContainer from './components/NotificationContainer.APP_TARGET';
import toggleUnreadNotifications from 'Actions/toggleUnreadNotifications';
import {
    notificationsSelector,
    notificationsMetaSelector,
    isGetNotificationsFetching,
    unreadNotificationsSelector,
    isNotificationsError
} from 'Selectors/notifications';
import {
    loadMoreNotifications,
    updateNotifications,
    getNotifications
} from 'Actions/notifications';

export const getPathname = value => {
    const url = new URL(value);

    return url.pathname.includes('/ad/')
        ? url.pathname.replace('/ad/', '/item/')
        : url.pathname + url.search;
};

export class NotificationHub extends React.Component {
    static propTypes = {
        loadMoreNotifications: PropTypes.func.isRequired,
        updateNotifications: PropTypes.func.isRequired,
        isFetching: PropTypes.bool,
        notifications: PropTypes.array,
        metadata: PropTypes.object,
        track: PropTypes.func.isRequired,
        onNotificationsClick: PropTypes.func,
        router: PropTypes.object.isRequired,
        toggleUnreadNotifications: PropTypes.func.isRequired,
        unreadNotifications: PropTypes.bool.isRequired,
        getNotifications: PropTypes.func,
        isNotificationsFetchingError: PropTypes.bool,
        filtersData: PropTypes.array
    };

    static defaultProps = {
        notifications: [],
        metadata: {},
        onNotificationsClick: () => {}
    };

    state = {
        filterCategory: ''
    }

    onNotificationsClick = notification => {
        if (notification.status !== 'opened') {
            const params = {
                status: {
                    [notification.messageId]: 'opened'
                }
            };

            this.props.updateNotifications(params);
        }

        this.track('notification_tap', {
            message_id: notification.messageId
        });

        const pathname = getPathname(notification.content.action.value);

        this.props.router.push(pathname);
        this.props.onNotificationsClick();
    };

    onBadgeClick = isOpen => {
        if (isOpen) {
            this.updateNotifications();
        }
    }

    updateNotifications = () => {
        const { notifications, metadata } = this.props;
        const notificationsOpen = [];
        const notificationsRead = [];
        const params = {
            status: {}
        };

        notifications.map(notification => {
            notificationsOpen.push(notification.messageId);
            if (notification.status === 'new') {
                params.status[notification.messageId] = 'seen';
                notificationsRead.push(notification.messageId);
            }
        });

        this.track('notifications_open', {
            messages_id: notificationsOpen
        });

        if (params.status && !isEmpty(params.status)) {
            this.props.updateNotifications(params);
            this.track('notification_read', {
                messages_id: notificationsRead
            });

            if (metadata.new <= notificationsRead.length) {
                this.props.toggleUnreadNotifications(false);
            }
        }
    }

    onSeeMoreClick = () => {
        this.props.loadMoreNotifications(res => {
            if (res.ok) {
                this.track('notification_get', {
                    unread_count: res.data.meta.new,
                    total_count: res.data.meta.total
                });
                this.updateNotifications();
            }
        }, this.state.filterCategory);
    }

    track = (event, props) => {
        this.props.track(event, props);
    }

    fetchNotificationsByCategory = categoryId => {
        this.setState({ filterCategory: categoryId });
        this.props.getNotifications(categoryId);
    }

    render() {
        const { notifications, metadata, isFetching, unreadNotifications, isNotificationsFetchingError, filtersData } = this.props;

        return (
            <NotificationContainer
                notifications={ notifications }
                onNotificationsClick={ this.onNotificationsClick }
                onBadgeClick={ this.onBadgeClick }
                onSeeMoreClick={ this.onSeeMoreClick }
                showLoader={ isFetching }
                unRead={ unreadNotifications }
                total={ metadata.total }
                fetchNotificationsByCategory={ this.fetchNotificationsByCategory }
                filterCategory={ this.state.filterCategory }
                isNotificationsFetchingError={ isNotificationsFetchingError }
                filtersData={ filtersData }
            />
        );
    }
}

export const mapStateToProps = state => {
    return {
        notifications: notificationsSelector(state),
        metadata: notificationsMetaSelector(state),
        isFetching: isGetNotificationsFetching(state),
        unreadNotifications: unreadNotificationsSelector(state),
        isNotificationsFetchingError: isNotificationsError(state),
        filtersData: state.notificationsFilters.data
    };
};

export const mapDispatchToProps = ({
    toggleUnreadNotifications: state => dispatch => dispatch(toggleUnreadNotifications(state)),
    loadMoreNotifications: (callback, categoryId) => dispatch => dispatch(loadMoreNotifications(categoryId)).then(res => callback(res)),
    updateNotifications: params => dispatch => dispatch(updateNotifications(params)),
    getNotifications: categoryId => dispatch => dispatch(getNotifications(categoryId))
});

export default compose(
    withRouter,
    withTrack,
    connect(mapStateToProps, mapDispatchToProps)
)(NotificationHub);
