import updateUserSession from 'Actions/userSession';
import * as userSession from 'Helpers/userSession';
import withConfig from 'HOCs/withConfig/withConfig';
import withCookies from 'HOCs/withCookies/withCookies';
import hoistNonReactStatic from 'hoist-non-react-statics';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { userSessionSelector } from 'Selectors/items';

/**
 * Sets sessionLong variable in state.user is not present
 * @param {*} Component
 */
const withSessionLong = Component => {
    class withSessionLong extends React.Component {
        componentDidMount() {
            const {
                config,
                cookies,
                setUserSession,
                userSessionLong
            } = this.props;
            const cookieName = config.get('sessionCookieName');
            const cookieContent = cookies.get(cookieName);

            if (!userSessionLong && cookieContent) {
                setUserSession(cookieContent);
            }
        }

        render = () => <Component { ...this.props } />;
    }

    withSessionLong.propTypes = {
        config: PropTypes.shape({
            get: PropTypes.func.isRequired
        }),
        cookies: PropTypes.shape({
            get: PropTypes.func.isRequired
        }),
        setUserSession: PropTypes.func.isRequired,
        userSessionLong: PropTypes.string
    };

    Component.displayName = `WithSessionLong(${Component.displayName || Component.name || 'Component'})`;

    const mapStateToProps = state => ({
        userSessionLong: userSessionSelector(state)
    });

    const mapDispatchToProps = ({
        setUserSession: session => dispatch => dispatch(updateUserSession(userSession.getSessionLong(session)))
    });

    const connectedComponent = compose(
        connect(mapStateToProps, mapDispatchToProps),
        withCookies,
        withConfig
    )(withSessionLong);

    return hoistNonReactStatic(connectedComponent, Component);
};

export default withSessionLong;
