import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import css from './RedDot.scss';

const helper = import(/* webpackChunkName: "lastClickTimestamp-helper" */ '../helpers/helpers');

const RedDot = ({ className, threads }) => {
    const [timestamp, setTimestamp] = useState(null);

    /**
     *
     * @param {number | null} latestTimeStamp
     * @param {Array<{lastMsgTimestamp: number, meetingStatus?: "accepted" | "rejected"}>} threads
     * @returns {boolean}
     */

    const isNewCancelledThreadPresent = (latestTimeStamp, threads) => {
        return !!threads.find(thread => {
            /**
             * If latestTimeStamp is null
             * return true if there is any rejected thread
             */

            if (typeof latestTimeStamp !== 'number') {
                return thread.meetingStatus === 'rejected';
            }

            return thread.meetingStatus === 'rejected' && thread.lastMsgTimestamp > latestTimeStamp;
        });
    };

    React.useEffect(() => {
        helper.then(({ lastClickTimestamp }) => {
            setTimestamp(lastClickTimestamp.timestamp);
            lastClickTimestamp.subscribe(newTimestamp => {
                setTimestamp(newTimestamp);
            });
        });
    }, []);

    const newCancelledThreadIsPresent = isNewCancelledThreadPresent(timestamp, threads);

    if (!newCancelledThreadIsPresent) {
        return null;
    }

    return (<div className={ cx(css.container, className) }>
        <div className={ cx(css.redDot) } />
    </div>);
};

RedDot.propTypes = {
    className: PropTypes.string,
    threads: PropTypes.array.isRequired
};

RedDot.defaultProps = {
    threads: []
};
export default RedDot;
