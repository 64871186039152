import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'panamera-react-ui';
import { compose } from 'redux';
import withConfig from 'HOCs/withConfig/withConfig';
import Icon from 'Components/ThematicIcon/ThematicIcon';

import css from './Logo.scss';
import deviceCss from './Logo.device.scss';
import withTrack from 'HOCs/withTrack/withTrack';
import { ICON_OLX_AUTOS_LOGO_SIZE, ICON_OLX_LOGO_SIZE } from 'Constants/header';
import { UserSession, EvaluationConstants } from 'olx-autos-landing-page';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import { SVG_TYPE } from 'Constants/images';

export const Logo = ({ isOnListing, track, logoUrl, logoSize, logoWidth, logoHeight, logoAltText, isPaidCampaign, config }) => {
    const evalState = UserSession.getUserEvaluationState();
    const { EVALUATION_STATES } = EvaluationConstants;
    const isevalCallback = evalState === EVALUATION_STATES.CALLBACK_REQUESTED;
    const isMxCl = config?.get('olxAutos', 'isMXCL');
    let routeLink;

    if (isMxCl) {
        routeLink = '/olxmobbi';
    }
    else if (isevalCallback && isPaidCampaign) {
        routeLink = config.get('landingPageRoutes', 'sell-organic');
    }
    else {
        routeLink = '/';
    }

    const handleClick = useCallback(() => {
        if (isevalCallback) {
            UserSession.setUserEvaluationState(EVALUATION_STATES.INITIAL);
            UserSession.deleteEvaluationFormValues();
            UserSession.deleteLastSelectionOption();
        }
        track('explore_tap', { select_from: 'logo' });
    }, [track, EVALUATION_STATES, isevalCallback]
    );

    return (
        <div className={ classNames(
            css.logo,
            { [css.olxAutos]: logoSize === ICON_OLX_AUTOS_LOGO_SIZE },
            { [deviceCss.mobileHiddenLogo]: isOnListing,
                [css.disableClick]: isPaidCampaign && !isevalCallback }
        ) }>
            <Link
                to={ routeLink }
                aa
                data-aut-id="btnOlxLogo"
                onClick={ handleClick }
            >
                {logoUrl && <ImageWrapper
                    fileName={ logoUrl }
                    types={ SVG_TYPE }
                    width={ logoWidth }
                    height={ logoHeight }
                    alt={ logoAltText }
                    className={ css.logoImg }
                /> }
                {!logoUrl && <Icon
                    icon="olx"
                    size={ ICON_OLX_LOGO_SIZE }
                    alt={ logoAltText }
                />}
            </Link>
        </div>
    );
};

Logo.propTypes = {
    isOnListing: PropTypes.bool,
    track: PropTypes.func.isRequired,
    logoUrl: PropTypes.string,
    logoSize: PropTypes.number,
    isPaidCampaign: PropTypes.bool,
    config: PropTypes.object,
    logoWidth: PropTypes.number,
    logoHeight: PropTypes.number,
    logoAltText: PropTypes.string
};

export default compose(
    withConfig,
    withTrack
)(Logo);
