import React from 'react';
import PropTypes from 'prop-types';
import withConfig from 'HOCs/withConfig/withConfig';
import { urlJoin } from 'Helpers/url';

export const LamudiBranding = ({ config, assetUrl, redirectionUrl, cssClass }) => {
    const { staticAssets } = config.get();
    const url = urlJoin(staticAssets, assetUrl);

    return (
        <a title="lamudi-branding" href={ redirectionUrl } className={ cssClass }>
            <img src={ url } alt={ 'lamudi-branding' } />
        </a>
    );
};

LamudiBranding.propTypes = {
    config: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired,
    assetUrl: PropTypes.string.isRequired,
    redirectionUrl: PropTypes.string,
    cssClass: PropTypes.string
};

LamudiBranding.defaultProps = {
    config: {},
    assetUrl: '',
    cssClass: ''
};

export default withConfig(LamudiBranding);
