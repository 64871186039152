import React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { ContextVwoConsumer } from 'Components/VwoProvider/VwoContext';

const withVwo = Component => {
    const ComponentWithVwo = props => (
        <ContextVwoConsumer>
            {
                vwoClientInstance => <Component { ...{ vwoClientInstance } } { ...props } />
            }
        </ContextVwoConsumer>
    );

    ComponentWithVwo.displayName = `withVwo(${Component.displayName || Component.name})`;

    return hoistNonReactStatic(ComponentWithVwo, Component);
};

export default withVwo;
