export const COLUMN_LINKS = [
    'popular-categories',
    'popular-locations',
    'popular-searches',
    'other-countries',
    'corporate-links',
    'popular-sell-location',
    'popular-sell-brand'
];

export const DISABLED_SELL_FLOW_LINKS = [
    'popular-sell-location',
    'popular-sell-brand'
];

export const TOP_COLUMN_LINKS = COLUMN_LINKS.filter(column => column !== 'other-countries');
export const COLUMN_ICONS = [
    'social-networks',
    'mobile-apps'
];

export const TOP_SECTIONS_TYPE = {
    H1_BREADCRUMB: 'H1_BREADCRUMB',
    RELATED_SEARCHES: 'RELATED_SEARCHES'
};

export const CARET_ICON = {
    DOWN: 'arrowDown',
    RIGHT: 'arrowRight'
};
