import { isEmpty } from 'Helpers/objects';
import { createSelector } from 'reselect';

export const itemSelector = createSelector(
    (state, itemId) => state?.myZone?.adsData[itemId],
    adDetails => {
        let item = {};

        if (adDetails && !isEmpty(adDetails)) {
            item = {
                displayTitle: adDetails?.title,
                price: adDetails?.price?.value?.display,
                image: adDetails?.imageData
            };
        }
        return item;
    }
);

export const getItemDetailsSelector = ({ id, data }) => {
    let itemDetails = {};

    if (id && !isEmpty(data)) {
        itemDetails = {
            adId: id,
            sellerId: data?.sellerDetails?.id,
            categoryId: data?.itemRawData?.categoryId,
            sellerType: data?.sellerDetails?.sellerType
        };
    }
    return itemDetails;
};

