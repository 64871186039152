/* eslint-disable max-depth */
/* global window */
import Auth from '../app/Auth';
import { MANUAL_LOCATIONS } from 'Constants/localStorage';
import { supportLocalStorage } from './localStorage';

const getKey = () => {
    const user = Auth.getUser();

    if (user && user.id) {
        return `${MANUAL_LOCATIONS.key}_${user.id}`;
    }
    return undefined;
};

const add = value => {
    if (supportLocalStorage()) {
        try {
            window.localStorage.setItem(getKey(), JSON.stringify(value));
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.error(e.message);
        }
    }
};

const get = () => {
    try {
        if (supportLocalStorage()) {
            const saved = window.localStorage.getItem(getKey());

            return saved ? JSON.parse(saved) : null;
        }
        return null;
    }
    catch (e) {
        return null;
    }
};

export {
    add,
    get,
    getKey
};
