import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

// COMPONENTS
import withTrack from 'HOCs/withTrack/withTrack';
import Button from 'Components/Button/Button';

// ACTIONS
import { getNotificationsFilters } from 'Actions/notifications';

// CSS
import css from './NotificationsFilters.scss';

const NotificationsFilters = ({
    filtersData = [],
    customClassname = '',
    recieveNotifications,
    isFetchingFilters,
    isError,
    fetchNotificationsByCategory,
    track
}) => {
    const [selectedFilter, setFilter] = useState(filtersData[0]?.key || '');

    useEffect(() => {
        if (!filtersData.length) {
            recieveNotifications();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recieveNotifications]);

    useEffect(() => {
        if (filtersData.length) {
            setFilter(filtersData[0]?.key);
        }
    }, [filtersData, filtersData.length, setFilter]);

    const updateFilterSelection = (e, filterKey) => {
        e.preventDefault();
        e.stopPropagation();
        setFilter(filterKey);
        fetchNotificationsByCategory(filterKey);
        e.target.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        track('notifications_chip_clicked', {
            notification_filter_name: filterKey
        });
    };

    if (isError) {
        return null;
    }

    if (isFetchingFilters && !filtersData.length) {
        return (<nav className={ cx(css.filtersBar) }>
            <ul className={ css.filterList } data-aut-id={ 'filtersList' }>
                <li className={ css.fetchingButton }>&nbsp;</li>
                <li className={ css.fetchingButton }>&nbsp;</li>
                <li className={ css.fetchingButton }>&nbsp;</li>
                <li className={ css.fetchingButton }>&nbsp;</li>
                <li className={ css.fetchingButton }>&nbsp;</li>
                <li className={ css.fetchingButton }>&nbsp;</li>
            </ul>
        </nav>);
    }

    if (filtersData.length === 1) {
        return null;
    }

    return (<nav className={ cx(css.filtersBar, customClassname) }>
        <ul className={ css.filterList } data-aut-id={ 'filtersList' }>
            {
                filtersData.map(filter => (<li data-aut-id={ filter.key } key={ filter.key }>
                    <Button
                        className={ cx(css.filterButton, {
                            [css.selected]: filter.key === selectedFilter
                        }) }
                        size="normal"
                        type="secondaryBtn"
                        onClick={ e => updateFilterSelection(e, filter.key) }
                    >
                        { filter.value }
                    </Button>
                </li>))
            }
        </ul>
    </nav>);
};

NotificationsFilters.propTypes = {
    recieveNotifications: PropTypes.func,
    isFetchingFilters: PropTypes.bool,
    isError: PropTypes.bool,
    filtersData: PropTypes.array,
    fetchNotificationsByCategory: PropTypes.func
};

NotificationsFilters.propTypes = {
    customClassname: PropTypes.string,
    filtersData: PropTypes.array,
    track: PropTypes.func
};

const mstp = state => ({
    isFetchingFilters: state.notificationsFilters.isFetching,
    isError: state.notificationsFilters.isError,
    filtersData: state.notificationsFilters.data
});

const mdtp = dispatch => ({
    recieveNotifications: () => dispatch(getNotificationsFilters({
        key: 'category_ID'
    }))
});

export default compose(
    withTrack,
    connect(mstp, mdtp))(NotificationsFilters);
