export const urlPromoNews = {
    newsUrlCla: 'https://olx.co.id/news/?utm_source=HomepageHeader&utm_medium=CTR&utm_campaign=ID%7CMIX%7CCLA%7CPD%7CHH%7CPROS%7CWEB%7CCTR%7CLPV%7CNewsButton%7CCLA-All%7CNews%7C20240807&utm_term=NA&utm_content=NA',
    promoUrlCla: 'https://olx.co.id/news/promo?utm_source=HomepageHeader&utm_medium=CTR&utm_campaign=ID%7CMIX%7CCLA%7CPD%7CHH%7CPROS%7CWEB%7CCTR%7CLPV%7CPromoButton%7CCLA-All%7CNewsPromo%7C20240807&utm_term=NA&utm_content=NA',
    newsUrlCtx: 'https://olx.co.id/news/?utm_source=HomepageHeader&utm_medium=CTR&utm_campaign=ID%7CMIX%7CCTX%7CPD%7CHH%7CPROS%7CWEB%7CCTR%7CLPV%7CNewsButton%7CCTx-All%7CNews%7C20240807&utm_term=NA&utm_content=NA',
    promoUrlCtx: 'https://olx.co.id/news/promo?utm_source=HomepageHeader&utm_medium=CTR&utm_campaign=ID%7CMIX%7CCTX%7CPD%7CHH%7CPROS%7CWEB%7CCTR%7CLPV%7CPromoButton%7CCTx-All%7CNewsPromo%7C20240807&utm_term=NA&utm_content=NA',
    newsUrlClaMobile: 'https://olx.co.id/news/?utm_source=HomepageHeader&utm_medium=CTR&utm_campaign=ID%7CMIX%7CCLA%7CPD%7CHH%7CPROS%7CPWA%7CCTR%7CLPV%7CNewsButton%7CCLA-All%7CNews%7C20240807&utm_term=NA&utm_content=NA',
    promoUrlClaMobile: 'https://olx.co.id/news/promo?utm_source=HomepageHeader&utm_medium=CTR&utm_campaign=ID%7CMIX%7CCLA%7CPD%7CHH%7CPROS%7CPWA%7CCTR%7CLPV%7CPromoButton%7CCLA-All%7CNewsPromo%7C20240807&utm_term=NA&utm_content=NA',
    newsUrlCtxMobile: 'https://olx.co.id/news/?utm_source=HomepageHeader&utm_medium=CTR&utm_campaign=ID%7CMIX%7CCTX%7CPD%7CHH%7CPROS%7CPWA%7CCTR%7CLPV%7CNewsButton%7CCTx-All%7CNews%7C20240807&utm_term=NA&utm_content=NA',
    promoUrlCtxMobile: 'https://olx.co.id/news/promo?utm_source=HomepageHeader&utm_medium=CTR&utm_campaign=ID%7CMIX%7CCTX%7CPD%7CHH%7CPROS%7CPWA%7CCTR%7CLPV%7CPromoButton%7CCTx-All%7CNewsPromo%7C20240807&utm_term=NA&utm_content=NA',
    appsLinkCla: 'https://apps.apple.com/id/app/olx-indonesia/id660161040?utm_source=HomepageHeader&utm_medium=NAU&utm_campaign=ID%7CMIX%7CCTX%7CPD%7CHH%7CPROS%7CWEB%7CNAU%7CDownloads%7CDownloadWidget%7CCTx-All%7CAppstore%7C20240807&utm_term=NA&utm_content=NA',
    playstoreLinkCla: 'https://play.google.com/store/apps/details?id=com.app.tokobagus.betterb&hl=id&utm_source=HomepageHeader&utm_medium=NAU&utm_campaign=ID%7CMIX%7CCTX%7CPD%7CHH%7CPROS%7CWEB%7CNAU%7CDownloads%7CDownloadWidget%7CCTx-All%7CPlaystore%7C20240807&utm_term=NA&utm_content=NA',
    appsLinkCtx: 'https://apps.apple.com/id/app/olx-indonesia/id660161040?utm_source=HomepageHeader&utm_medium=NAU&utm_campaign=ID%7CMIX%7CCLA%7CPD%7CHH%7CPROS%7CWEB%7CNAU%7CDownloads%7CDownloadWidget%7CCLA-All%7CAppstore%7C20240807&utm_term=NA&utm_content=NA',
    playstoreLinkCtx: 'https://play.google.com/store/apps/details?id=com.app.tokobagus.betterb&hl=id&utm_source=HomepageHeader&utm_medium=NAU&utm_campaign=ID%7CMIX%7CCLA%7CPD%7CHH%7CPROS%7CWEB%7CNAU%7CDownloads%7CDownloadWidget%7CCLA-All%7CPlaystore%7C20240807&utm_term=NA&utm_content=NA'

};
