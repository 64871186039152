import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import IconWrapper from 'Components/IconWrapper/IconWrapper';
import css from '../Footer.scss';
import { convertToAbsoluteImageURI } from 'Helpers/images';
import { fileNames } from 'Constants/imageUrls';
import LazyImage from 'Components/Image/LazyImage';
import withConfig from 'HOCs/withConfig/withConfig';
import { WEBP_EXT, PNG_EXT } from 'Constants/images';

const assets = {
    networks: {
        Facebook: 'facebookProfile',
        Twitter: 'twitterProfile',
        Instagram: 'instagramProfile',
        Youtube: 'youtubeProfile',
        LinkedIn: 'linkedInProfile',
        Tiktok: 'tiktokProfile'
    },
    apps: {
        Android: fileNames.ANDROID_ASSET,
        Apple: fileNames.APPLE_ASSET
    }
};
const appsAltTexts = {
    Android: {
        id: 'android_app_store_alt_text'
    },
    Apple: {
        id: 'apple_app_store_alt_text'
    }
};

const getSourceConfig = (staticAssetUrl, type, target, theme) => ({
    webp: convertToAbsoluteImageURI(assets[type][target], WEBP_EXT, staticAssetUrl, '', '', theme)
});

export const Icon = ({ intl, type, target, brand, config }) => {
    const staticAssetUrl = config.get('staticAssets');
    const theme = config.get('theme', 'id');

    if (assets[type] && assets[type][target]) {
        switch (type) {
            case 'networks':
                return (
                    <IconWrapper
                        direction="ltr"
                        icon={ assets[type][target] }
                        name="IconButton"
                    />);
            case 'apps':
                return (
                    <LazyImage
                        sourceConfig={ getSourceConfig(staticAssetUrl, type, target, theme) }
                        url={ convertToAbsoluteImageURI(assets[type][target], PNG_EXT, staticAssetUrl, '', '', theme) }
                        alt={ intl.formatMessage(appsAltTexts[target], { brand }) }
                        className={ css.cta }
                    />
                );
        }
    }

    return null;
};

Icon.propTypes = {
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired
    }).isRequired,
    type: PropTypes.string,
    target: PropTypes.string,
    brand: PropTypes.string,
    config: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired
};

export default compose(withConfig, injectIntl)(Icon);
