import React from 'react';
import loadable from 'Components/AsyncModuleLoader/AsyncModuleLoader';
import ReactObserver from 'Components/ReactObserver/ReactObserver';

function createLazyLoadComponent(Component, LoadingComponent, options) {
    return class LazyLoadextends extends React.Component {
        render() {
            return (
                <ReactObserver rootMargin={ options.rootMargin } as="div" triggerOnce="true">
                    {({ inView, ref }) => (
                        <div ref={ ref }>
                            { inView
                                && <Component { ...this.props } />
                                || <LoadingComponent />
                            }
                        </div>
                    )}
                </ReactObserver>
            );
        }
    };
}

function getLoadableComponent(loader, loading) {
    return loadable({
        loader,
        loading
    });
}

export default function lazyLoad(opts = {}) {
    const LoadingComponent = opts.loading || (() => null);
    const loader = opts.loader || (() => Promise.resolve());
    const options = { ...{ rootMargin: '20px' }, ...(opts.options || {}) };
    const Component = getLoadableComponent(loader, LoadingComponent);

    return createLazyLoadComponent(Component, LoadingComponent, options);
}

