import React from 'react';
import loadable from 'Components/AsyncModuleLoader/AsyncModuleLoader';
import loading from 'Components/asyncLoading/asyncLoading';
const Loading = loading();

export default loadable({
    loader: () =>
        import(
            /* webpackChunkName: "MyZoneDropdown" */
            './MyZoneDropdown'
        ),
    loading: <Loading />
});
