import React from 'react';
import PropTypes from 'prop-types';
import { NewIconButton } from 'panamera-react-ui';

import { THEMES } from 'Constants/bundles';
import withConfig from 'HOCs/withConfig/withConfig';

export const ThematicNewIconButton = ({
    config,
    color,
    ...rest
}) => {
    const theme = config.get('theme', 'id');
    const iconFillColor = theme === THEMES.LETGO && !color ? 'primaryLight3' : color;

    return <NewIconButton color={ iconFillColor } theme={ theme } { ...rest } />;
};

ThematicNewIconButton.propTypes = {
    config: PropTypes.shape({
        get: PropTypes.func.isRequired
    }),
    color: PropTypes.string
};

export default withConfig(ThematicNewIconButton);
