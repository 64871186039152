import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import NewIconButton from 'Components/ThematicNewIconButton/ThematicNewIconButton';

import classNames from 'classnames';
import {
    getLoggedUser,
    userIsLogged
} from 'Selectors/user';
import { urls as usersUrls } from 'Reducers/users';
import css from './LanguageList.scss';
import { patch } from '../../../../server';

export class LanguageList extends React.Component {
    static propTypes = {
        langSupp: PropTypes.array.isRequired,
        selectLang: PropTypes.string.isRequired,
        className: PropTypes.string,
        onSelect: PropTypes.func.isRequired,
        updateExistingUser: PropTypes.func,
        loggedUser: PropTypes.object.isRequired,
        _userIsLogged: PropTypes.bool.isRequired
    }

    static defaultProps = {
        className: ''
    };

    renderLanguageList = () => {
        const { className, selectLang, langSupp } = this.props;
        const onClick = code => () => this.setLangPref(code);

        return (
            <div
                key="languageDropdown"
                className={ classNames(css.list, className) }
                data-aut-id="languages"
            >
                <ul>
                    { langSupp.map((langObj, i) => (
                        <li onClick={ onClick(langObj.param) } key={ i }>
                            <span>{langObj.name}</span>
                            {selectLang === langObj.param
                            && <span className={ css.okIcon }>
                                <NewIconButton
                                    icon="ok"
                                    color="blackText"
                                    animation={ false }
                                />
                            </span>
                            }
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    setLangPref = code => {
        const { _userIsLogged, loggedUser } = this.props;

        if (_userIsLogged) {
            const params = {
                lang: code
            };

            this.props.updateExistingUser(loggedUser.id, params, () => {
                this.props.onSelect(code);
            });
        }
        else {
            this.props.onSelect(code);
        }
    }

    render() {
        return (
            this.renderLanguageList()

        );
    }
}
export const mapStateToProps = state => {
    return {
        loggedUser: getLoggedUser(state),
        _userIsLogged: userIsLogged(state)
    };
};

export const mapDispatchToProps = dispatch => {
    return {
        updateExistingUser: (userId, params, callback) => dispatch(patch(usersUrls.getExistingUser(userId), 'ELEMENT_USERS', params)).then(res => callback(res))
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(LanguageList);
