import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Loader } from 'panamera-react-ui';

import css from './LazyLoader.scss';

const LazyLoader = props => <div className={ classNames(css.loaderContainer, { [css.header]: props.isPlaceHolder }) }> <Loader { ...props } className={ css.loader } /> </div>;

LazyLoader.propTypes = {
    isPlaceHolder: PropTypes.bool
};

export default LazyLoader;
