import React from 'react';
import PropTypes from 'prop-types';
import css from './NotificationContainer.desktop.scss';
import { Loader } from 'panamera-react-ui';
import withConfig from 'HOCs/withConfig/withConfig';
import NotificationsFilters from './NotificationsFilters';
import AsyncNotificationsList from './AsyncNotificationsList';
import { FormattedMessage as Translation } from 'react-intl';
import EmptyState from './EmptyState';
import classNames from 'classnames';
import NewIconButton from 'Components/ThematicNewIconButton/ThematicNewIconButton';
import EmptyStateNew from './EmptyStateNew';
import { NO_NOTIFICATIONS, fileNames } from 'Constants/imageUrls';

export class NotificationContainer extends React.PureComponent {
    static propTypes = {
        notifications: PropTypes.array,
        onNotificationsClick: PropTypes.func.isRequired,
        onBadgeClick: PropTypes.func.isRequired,
        showLoader: PropTypes.bool,
        unRead: PropTypes.bool,
        total: PropTypes.number,
        onSeeMoreClick: PropTypes.func.isRequired,
        config: PropTypes.shape({
            get: PropTypes.func
        }),
        fetchNotificationsByCategory: PropTypes.func,
        filterCategory: PropTypes.string,
        isNotificationsFetchingError: PropTypes.bool,
        filtersData: PropTypes.array
    };

    static defaultProps = {
        notifications: [],
        showLoader: false,
        unRead: false,
        total: 100
    };

    constructor(props) {
        super(props);

        this.state = {
            showNotifications: false
        };
        this.isNotificationsRevamp = !!props.config.get('notifications', 'isDesignRevamp');
        this.staticAssetsPath = props.config.get('staticAssets');
    }

    componentDidMount() {
        if (typeof document !== 'undefined') {
            document.addEventListener('click', this.handleClickOutside, true);
        }
    }

    componentWillUnmount() {
        if (typeof document !== 'undefined') {
            document.removeEventListener('click', this.handleClickOutside, true);
        }
    }

    handleClickOutside = event => {
        const domNode = this.notificationHub;

        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                showNotifications: false
            });
        }
    }

    toggleNotifications = () => {
        this.setState({
            showNotifications: !this.state.showNotifications
        }, () => {
            this.props.onBadgeClick(this.state.showNotifications);
        });
    }

    onSeeMoreClick = () => {
        const { notifications } = this.props;
        const lastNotification = notifications[notifications.length - 1];

        this.props.onSeeMoreClick(lastNotification);
    }

    onNotificationsClick = notification => {
        this.toggleNotifications();
        this.props.onNotificationsClick(notification);
    }

    getRevampClass = () => {
        let overrideCSS = '';

        if (this.isNotificationsRevamp && this.props.filtersData.length === 1) {
            overrideCSS = css.customListOverrideWithoutFilter;
        }
        else if (this.isNotificationsRevamp) {
            overrideCSS = css.customListOverride;
        }
        return overrideCSS;
    }

    render() {
        const { notifications, showLoader, total, unRead, fetchNotificationsByCategory, filterCategory, isNotificationsFetchingError } = this.props;
        const { showNotifications } = this.state;
        let content;
        const showMore = total > notifications.length;

        const filtersBar = (this.isNotificationsRevamp && <NotificationsFilters fetchNotificationsByCategory={ fetchNotificationsByCategory } />);

        if (showNotifications && isNotificationsFetchingError) {
            content = (
                <div
                    className={ `${css.notificationsContainer} ${this.isNotificationsRevamp ? css.notificationRevamp : null}` }
                >
                    { filtersBar }
                    {
                        this.isNotificationsRevamp
                            ? (<EmptyStateNew
                                textId={ 'error_subtitle' }
                                iconURL={ `${this.staticAssetsPath}${fileNames.ERROR_IMG}` }
                            />)
                            : <EmptyState />
                    }
                </div>
            );
        }
        else if (showLoader && showNotifications && !notifications.length) {
            content = (
                <div
                    className={ `${css.notificationsContainer} ${this.isNotificationsRevamp ? css.notificationRevamp : null}` }
                    data-aut-id="loader"
                >
                    { filtersBar }
                    <div className={ `${ css.loader } ${ css.big }` }>
                        <Loader
                            containerSize="60px"
                        />
                    </div>
                </div>);
        }
        else if (showNotifications && notifications.length) {
            content = (
                <div
                    className={ `${css.notificationsContainer} ${this.isNotificationsRevamp ? css.notificationRevamp : null}` }
                >
                    { filtersBar }
                    { showLoader
                        && <div
                            className={ `${ css.loader } ${ css.small }` }
                            data-aut-id="loader"
                        >
                            <Loader
                                containerSize="30px"
                            />
                        </div>
                    }
                    <AsyncNotificationsList
                        notifications={ notifications }
                        onNotificationsClick={ this.onNotificationsClick }
                        seeMore={ total > notifications.length }
                        customClass={ this.getRevampClass() }
                        isNotificationsRevamp={ this.isNotificationsRevamp }
                    />
                    { showMore
                        && (
                            <div
                                className={ css.seeMore }
                                onClick={ this.onSeeMoreClick }
                                data-aut-id="seeMore"
                            >
                                <Translation id="showMore" />
                            </div>
                        )
                    }
                </div>
            );
        }
        else if (showNotifications) {
            content = (
                <div
                    className={ `${css.notificationsContainer} ${this.isNotificationsRevamp ? css.notificationRevamp : null}` }
                >
                    { filtersBar }
                    {
                        this.isNotificationsRevamp
                            ? (<EmptyStateNew
                                textId={ filterCategory ? 'noNotificationsFiltersFound' : 'noNotificationsFound' }
                                iconURL={ `${this.staticAssetsPath}${NO_NOTIFICATIONS}` }
                            />)
                            : <EmptyState />
                    }
                </div>
            );
        }

        return (
            <div
                className={ classNames(
                    css.notificationHub,
                    {
                        [css.unReadNotification]: unRead
                    }
                ) }
                data-aut-id="notificationContainer"
                ref={ element => {
                    this.notificationHub = element;
                } }
            >
                <NewIconButton
                    name="notifications"
                    icon="notifications"
                    onClick={ this.toggleNotifications }
                    data-aut-id="badge"
                    active={ showNotifications }
                />
                { content }
            </div>
        );
    }
}

export default withConfig(NotificationContainer);
