import React from 'react';
import { bool, shape, object, string } from 'prop-types';
import { getIsCheckoutEnabled } from 'checkout-web/helpers';
import { compose } from 'redux';
import { connect } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import Auth from '../../Auth';
import { RESERVED_STATUS } from 'Constants/chat';
import withConfig from 'HOCs/withConfig/withConfig';
import { FeatureMapFlag } from 'Constants/constants';
import { checkIfSuperUser } from 'Helpers/premiumUser';
import { isCocoFofoUser } from 'Helpers/item';

const checkDealerType = (dealerTypeData = [], dealerType) => {
    if (dealerTypeData.length === 0) {
        return true;
    }
    return dealerTypeData.some(
        item => item.toString() === dealerType);
};

export const featureHelper = (featureData, categoryId, dealerType) => {
    const isFeatureAvailable = featureData?.exceptions?.some(item => item.category && item.category.toString() === categoryId?.toString() && checkDealerType(item.dealerType, dealerType));

    if (isFeatureAvailable) {
        return !featureData.default;
    }

    return featureData.default;
};

const withFeatureFlagsFactory = (paramId = 'id') => Component => {
    const ComponentWithFeatureFlags = props => {
        const { users, items, params, myZone, isMyZone, features, config } = props;
        const itemId = (params && params[paramId]) || props.itemId;
        const item = isMyZone ? myZone?.adsData[itemId] : items.elements[itemId];
        const userId = item && item.user_id;
        const user = users.elements[userId];
        const categoryId = item?.category_id;
        const dealer_type = user?.dealer?.car?.dealer_type;

        const showInterest_adpv = features?.feature_flags?.[FeatureMapFlag.interest]
            && !!(features?.[FeatureMapFlag.interest]?.adpv)
            && featureHelper(features[FeatureMapFlag.interest].adpv, categoryId, dealer_type);

        const showInterest_chat = features?.feature_flags?.[FeatureMapFlag.interest]
            && !!(features?.[FeatureMapFlag.interest]?.chat)
            && featureHelper(features[FeatureMapFlag.interest].chat, categoryId, dealer_type);

        const showInterest_inbox = features?.feature_flags?.[FeatureMapFlag.interest]
            && !!(features?.[FeatureMapFlag.interest]?.chat_inbox)
            && featureHelper(features[FeatureMapFlag.interest].chat_inbox, categoryId, dealer_type);

        const showRequestCallback_adpv = features.feature_flags?.[FeatureMapFlag.requestCallback]
            && !!(features?.[FeatureMapFlag.requestCallback]?.adpv)
            && featureHelper(features[FeatureMapFlag.requestCallback].adpv, categoryId, dealer_type);

        const showRequestCallback_chat = features.feature_flags?.[FeatureMapFlag.requestCallback]
            && !!(features?.[FeatureMapFlag.requestCallback]?.chat)
            && featureHelper(features[FeatureMapFlag.requestCallback].chat, categoryId, dealer_type);

        const showRequestCallback_inbox = features.feature_flags?.[FeatureMapFlag.requestCallback]
            && !!(features?.[FeatureMapFlag.requestCallback]?.chat_inbox)
            && featureHelper(features[FeatureMapFlag.requestCallback].chat_inbox, categoryId, dealer_type);

        const isChatEnabled = features.feature_flags?.[FeatureMapFlag.chat]
            && (Auth.getUser()?.id !== userId || !Auth.isLoggedIn());

        const showChat_adpv = isChatEnabled
            && !!(features?.[FeatureMapFlag.chat]?.adpv)
            && featureHelper(features[FeatureMapFlag.chat].adpv, categoryId, dealer_type);

        const showChat_inbox = isChatEnabled
            && !!(features?.[FeatureMapFlag.chat]?.chat_inbox)
            && featureHelper(features[FeatureMapFlag.chat].chat_inbox, categoryId, dealer_type);

        const isCallEnabled = features.feature_flags?.[FeatureMapFlag.call]
            && (Auth.getUser()?.id !== userId || !Auth.isLoggedIn())
            && ((user?.is_phone_visible && item?.has_phone_param)
                || (user?.is_phone_visible && !item?.has_phone_param && user?.has_phone));

        const showCall_adpv = isCallEnabled
            && !!(features?.[FeatureMapFlag.call]?.adpv)
            && featureHelper(features[FeatureMapFlag.call].adpv, categoryId, dealer_type);

        const showCall_chat = isCallEnabled
            && !!(features?.[FeatureMapFlag.call]?.chat)
            && featureHelper(features[FeatureMapFlag.call].chat, categoryId, dealer_type);

        const showCall_inbox = isCallEnabled
            && !!(features?.[FeatureMapFlag.call]?.chat_inbox)
            && featureHelper(features[FeatureMapFlag.call].chat_inbox, categoryId, dealer_type);

        const showOffer_adpv = features.feature_flags?.[FeatureMapFlag.offer]
            && !!(features?.[FeatureMapFlag.offer]?.adpv)
            && featureHelper(features[FeatureMapFlag.offer].adpv, categoryId, dealer_type);

        const showOffer_chat = features.feature_flags?.[FeatureMapFlag.offer]
            && !!(features?.[FeatureMapFlag.offer]?.chat)
            && featureHelper(features[FeatureMapFlag.offer].chat, categoryId, dealer_type);

        const isTestdriveEnabled = features.feature_flags?.[FeatureMapFlag.storeTestDrive];

        const showStoreTestdrive_adpv = isTestdriveEnabled
            && !!(features?.[FeatureMapFlag.storeTestDrive]?.adpv)
            && featureHelper(features[FeatureMapFlag.storeTestDrive].adpv, categoryId, dealer_type)
            && Auth.getUser()?.id !== userId
            && user?.enabled_features?.indexOf('store_test_drive') > -1;

        const showStoreTestdrive_chat = isTestdriveEnabled
            && !!(features?.[FeatureMapFlag.storeTestDrive]?.chat)
            && featureHelper(features[FeatureMapFlag.storeTestDrive].chat, categoryId, dealer_type)
            && Auth.getUser()?.id !== userId
            && user?.enabled_features?.indexOf('store_test_drive') > -1;

        const showStoreTestdrive_inbox = isTestdriveEnabled
            && !!(features?.[FeatureMapFlag.storeTestDrive]?.chat_inbox)
            && featureHelper(features[FeatureMapFlag.storeTestDrive].chat_inbox, categoryId, dealer_type)
            && Auth.getUser()?.id !== userId
            && user?.enabled_features?.indexOf('store_test_drive') > -1;

        const showHomeTestdrive_adpv = features.feature_flags?.[FeatureMapFlag.homeTestDrive]
            && !!(features?.[FeatureMapFlag.homeTestDrive]?.adpv)
            && featureHelper(features[FeatureMapFlag.homeTestDrive].adpv, categoryId, dealer_type)
            && Auth.getUser()?.id !== userId
            && user?.enabled_features?.indexOf('home_test_drive') > -1;

        const showHomeTestdrive_chat = features.feature_flags?.[FeatureMapFlag.homeTestDrive]
            && !!(features?.[FeatureMapFlag.homeTestDrive]?.chat)
            && featureHelper(features[FeatureMapFlag.homeTestDrive].chat, categoryId, dealer_type)
            && Auth.getUser()?.id !== userId
            && user?.enabled_features?.indexOf('home_test_drive') > -1;

        const isStoreVisitEnabled = features.feature_flags?.[FeatureMapFlag.storeVisit];

        const showStoreVisit_adpv = isStoreVisitEnabled
            && !!(features?.[FeatureMapFlag.storeVisit]?.adpv)
            && featureHelper(features[FeatureMapFlag.storeVisit].adpv, categoryId, dealer_type)
            && Auth.getUser()?.id !== userId
            && user?.enabled_features?.indexOf(FeatureMapFlag.storeVisit) > -1;

        const showStoreVisit_inbox = isStoreVisitEnabled
            && !!(features?.[FeatureMapFlag.storeVisit]?.chat_inbox)
            && featureHelper(features[FeatureMapFlag.storeVisit].chat_inbox, categoryId, dealer_type)
            && Auth.getUser()?.id !== userId
            && user?.enabled_features?.indexOf(FeatureMapFlag.storeVisit) > -1;

        const showWhatsAppCTA_adpv = isCallEnabled
            && !!(features?.[FeatureMapFlag.call]?.adpv)
            && featureHelper(features[FeatureMapFlag.call].adpv, categoryId, dealer_type)
            && checkIfSuperUser(user?.subscription_info?.type) && !isCocoFofoUser(user);

        const isCheckoutConfigEnabled = config.get('enableCheckout');
        const userItem = user || {};
        const finalItem = item && {
            ...item,
            metadata: {
                users: {
                    [item.user_id]: {
                        ...userItem
                    }
                }
            }
        };

        const isReserveFlow = getIsCheckoutEnabled({
            item: finalItem,
            isCheckoutConfigEnabled
        });

        const showReserve = isReserveFlow && item?.reserve?.status && item.reserve.status !== RESERVED_STATUS.NOTAVAILABLE;

        const showReserveOnInbox = isReserveFlow && item?.reserveData?.status && item.reserveData.status !== RESERVED_STATUS.NOTAVAILABLE;

        const showIvrNumber_adpv = !!(features.feature_flags?.[FeatureMapFlag.ivr])
            && !!(features?.[FeatureMapFlag.ivr]?.adpv)
            && featureHelper(features[FeatureMapFlag.ivr].adpv, categoryId, dealer_type)
            && (Auth.getUser()?.id !== userId || !Auth.isLoggedIn());

        const showIvrNumber_chat = !!(features.feature_flags?.[FeatureMapFlag.ivr])
            && !!(features?.[FeatureMapFlag.ivr]?.chat)
            && featureHelper(features[FeatureMapFlag.ivr].chat, categoryId, dealer_type)
            && (Auth.getUser()?.id !== userId || !Auth.isLoggedIn());

        const showIvrNumber_inbox = !!(features.feature_flags?.[FeatureMapFlag.ivr])
            && !!(features?.[FeatureMapFlag.ivr]?.chat_inbox)
            && featureHelper(features[FeatureMapFlag.ivr].chat_inbox, categoryId, dealer_type)
            && (Auth.getUser()?.id !== userId || !Auth.isLoggedIn());

        const isTransactionInbox = !!(features.feature_flags?.[FeatureMapFlag.transactionInbox]);

        const showTransactionInbox = !!(features?.[FeatureMapFlag.transactionInbox]?.chat_inbox)
            && featureHelper(features[FeatureMapFlag.transactionInbox].chat_inbox, categoryId, dealer_type);

        return (<Component
            showInterestOnAdpv={ showInterest_adpv }
            showInterestOnChat={ showInterest_chat }
            showInterestOnInbox={ showInterest_inbox }
            showRequestCallbackOnAdpv={ showRequestCallback_adpv }
            showRequestCallbackOnChat={ showRequestCallback_chat }
            showRequestCallbackOnInbox={ showRequestCallback_inbox }
            isChatEnabled={ isChatEnabled }
            showChatOnAdpv={ showChat_adpv }
            showChatOnInbox={ showChat_inbox }
            isCallEnabled={ isCallEnabled }
            showCallOnAdpv={ showCall_adpv }
            showCallOnChat={ showCall_chat }
            showCallOnInbox={ showCall_inbox }
            showOfferOnAdpv={ showOffer_adpv }
            showOfferOnChat={ showOffer_chat }
            showWhatsAppCTA={ showWhatsAppCTA_adpv }
            isTestdriveEnabled={ isTestdriveEnabled }
            showStoreTestdriveOnAdpv={ showStoreTestdrive_adpv }
            isStoreVisitEnabled={ isStoreVisitEnabled }
            showStoreVisitOnAdpv={ showStoreVisit_adpv }
            showStoreVisitOnInbox={ showStoreVisit_inbox }
            showStoreTestdriveOnChat={ showStoreTestdrive_chat }
            showStoreTestdriveOnInbox={ showStoreTestdrive_inbox }
            showHomeTestdriveOnAdpv={ showHomeTestdrive_adpv }
            showHomeTestdriveOnChat={ showHomeTestdrive_chat }
            showReserve={ showReserve }
            showReserveOnInbox={ showReserveOnInbox }
            isReserveFlow={ isReserveFlow }
            showIvrNumberOnAdpv={ showIvrNumber_adpv }
            showIvrNumberOnChat={ showIvrNumber_chat }
            showIvrNumberOnInbox={ showIvrNumber_inbox }
            isTransactionInbox={ isTransactionInbox }
            showTransactionInbox={ showTransactionInbox }
            { ...props } />);
    };

    const mapStateToProps = ({ users, items, meetings, myZone, features }) => ({ users, items, meetings, myZone, features });

    ComponentWithFeatureFlags.propTypes = {
        users: object.isRequired,
        items: object.isRequired,
        params: object.isRequired,
        meetings: shape({
            enabled: bool
        }).isRequired,
        features: object.isRequired,
        itemId: string,
        isMyZone: bool,
        myZone: object,
        experiments: object,
        featureFlag: string,
        config: object
    };

    ComponentWithFeatureFlags.defaultProps = {
        users: {},
        items: {},
        params: {},
        meetings: {},
        features: {},
        itemId: '',
        isMyZone: false,
        myZone: {}
    };

    ComponentWithFeatureFlags.displayName = `withFeatureFlags(${Component.displayName || Component.name})`;
    return compose(
        withConfig,
        connect(mapStateToProps),
        React.memo
    )(hoistNonReactStatics(ComponentWithFeatureFlags, Component));
};

const withFeatureFlags = withFeatureFlagsFactory();

withFeatureFlags.withParam = withFeatureFlagsFactory;

export default withFeatureFlags;
