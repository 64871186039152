export const disclaimerData = {
    olxid: {
        questionSection: {
            heading: 'Pertanyaan lebih lanjut?',
            description: 'Kamu bisa temukan jawaban pertanyaanmu di bagian',
            link: {
                text: 'FAQ',
                url: '/olxmobbi/pertanyaan-umum'
            },
            contactSection: [
                {
                    iconName: 'call',
                    text: [
                        '150264'
                    ],
                    props: { href: 'tel:150264',
                        tracking: {
                            event: 'item_tap_call',
                            chosen_option: 'footer_phone'
                        }
                    }
                },
                {
                    iconName: 'clock',
                    text: [
                        'Jam kerja: 09.00 - 18.00'
                    ]
                }
            ]
        },
        disclaimerSection: {
            heading: 'Batasan Kewajiban',
            description:
                'Merek dagang dan logo kendaraan yang ditampilkan adalah merek dagang dan logo yang bukan milik OLXmobbi, melainkan milik masing-masing pemilik yang terdaftar pada otoritas yang berwenang. Penggunaan merek dagang dan logo di Platform ini hanya untuk tujuan informasi dan bukan untuk promosi.'
        }
    },
    olxmx: {
        questionSection: {
            heading: '¿Tienes alguna duda?',
            description: 'Consulta las respuestas en nuestra sección de',
            link: {
                text: 'Preguntas frecuentes.',
                url: '/preguntas-frecuentes'
            },
            contactSection: [
                {
                    iconName: 'call',
                    text: [
                        '800 022 2886'
                    ],
                    props: {
                        href: 'tel:+8000222886',
                        tracking: {
                            event: 'item_tap_call',
                            chosen_option: 'footer_phone'
                        }
                    }
                },
                {
                    iconName: 'clock',
                    text: [
                        'L-S 9:00 am a 6:00 pm'
                    ]
                }
            ]
        },

        disclaimerSection: {
            heading: 'Descargo de responsabilidad:',
            description:
                'Las marcas y logotipos utilizados son marcas registradas que no son propiedad de OLX Autos/Frontier Car Group México, S.A. de C.V., son propiedad de los respectivos dueños registrados ante las autoridades competentes. El uso de las marcas y logotipos en la presente plataforma es con fines únicamente informativos y en ningún caso de promoción.'
        }
    },
    olxcl: {
        questionSection: {
            heading: '¿Tienes alguna duda?',
            description: 'Consulta las respuestas en nuestra sección de',
            link: {
                text: 'Preguntas frecuentes.',
                url: '/preguntas-frecuentes'
            },
            contactSection: [
                {
                    iconName: 'call',
                    text: [
                        '+56 2 3262 6080'
                    ],
                    props: {
                        href: 'tel:+56232626080',
                        to: 'tel:+56232626080',
                        tracking: {
                            event: 'item_tap_call',
                            chosen_option: 'footer_phone'
                        }
                    }
                },
                {
                    iconName: 'clock',
                    text: [
                        'L-V de 8:30 am a 8:00 pm',
                        'S de 10:00 am a 3:30 pm'
                    ]
                }
            ]
        },
        disclaimerSection: {
            heading: 'Descargo de responsabilidad:',
            description:
                'Las marcas y logotipos de los vehículos exhibidos son marcas que no son propiedad de OLX Autos, son propiedad de los respectivos dueños registrados ante las autoridades competentes. El uso de las marcas y logotipos en la presente Plataforma es con fines únicamente informativos y en ningún caso de promoción. '
        }
    },
    olxco: {
        questionSection: {
            heading: '¿Tienes alguna duda?',
            description: 'Contáctanos, nuestro equipo te asesorará',
            contactSection: [
                {
                    iconName: 'call',
                    text: [
                        '(57) 601 552000',
                        'L-V : 09.00 am - 06.00 pm',
                        'S : 09:00 am - 02.00 pm'
                    ],
                    props: {
                        href: 'tel:+601552000'
                    }
                },
                {
                    iconName: 'whatsapp',
                    text: [
                        '320 488 5389',
                        'L-V : 09.00 am - 06.00 pm',
                        'S : 09:00 am - 02.00 pm'
                    ],
                    props: {
                        href: 'https://wa.me/3204885389'
                    }
                },
                {
                    iconName: 'location',
                    text: [
                        'Horario centros de atención',
                        'L-S : 10:00 am - 06:00 pm',
                        'Para más información revisa el horario',
                        'de cada punto de atención'
                    ]
                }
            ]
        },
        disclaimerSection: {
            heading: 'Descargo de responsabilidad:',
            description:
                'Las marcas y logotipos utilizados para identificar los vehículos, se utilizan con fines únicamente informativos y de referencia. Dichas marcas no son propiedad de OLX Autos/Americas Car Group Colombia, S.A. / OLX Fin. y su utilización no debe generar confusión o asociación directa con sus titulares.'
        }
    },
    olxtr: {
        questionSection: {
            heading: 'Yardım için buradayız!',
            description: 'Aklına takılan bir soru olursa bize ulaşabilir veya dilersen bu bölüme göz atabilirsin:',
            link: {
                text: 'Sıkça Sorulan Sorular.',
                url: '/sikca-sorulan-sorular'
            },
            contactSection: [
                {
                    iconName: 'call',
                    text: [
                        '0850 955 50 50'
                    ],
                    props: {
                        href: 'tel:+08509555050',
                        tracking: {
                            event: 'item_tap_call',
                            chosen_option: 'footer_phone'
                        }
                    }
                },
                {
                    iconName: 'clock',
                    text: [
                        'Haftanın her günü 09:00 - 22:00'
                    ]
                }
            ]
        },
        disclaimerSection: {
            heading: 'Yasal uyarı:',
            description:
            `Kullanılan markalar ve logolar, otoplus letgo'ya ait olmayan tescilli ticari markalardır ve yetkili makamlara kayıtlı ilgili sahiplerin mülkiyetindedir. Bu platformda markaların ve logoların kullanımı yalnızca bilgilendirme amaçlıdır ve hiçbir şekilde tanıtım yapılmaz. 

Web sitesinde gösterilen araçların özellikleri, görüntüleri, detayları, fiyatı, garanti ve sigorta bilgileri sehven farklılıklar gösterebilmekte olup bu bilgiler, bağlayıcı, kesin ve geçerli bir teklif niteliği taşımamaktadır. Araçlarla ilgili otoplus lokasyonlarında sağlanan bilgiler nihai ve geçerli olup otoplus tüm bu bilgilerde önceden haber vermeksizin değişiklik yapma hakkına sahiptir.
            
Garanti kapsamı, İkinci El Motorlu Kara Taşıtlarının Ticareti Hakkındaki Yönetmelik (“Yönetmelik”) uyarınca kanuni olarak 8 yaşın veya 160bin km'nin altındaki araçlar için geçerlidir. Yönetmelik kapsamındaki şartları/limitleri sağlamayan araçlar garanti kapsamına girmemektedir.`
        }
    },
    olxpl: {
        questionSection: {
            heading: 'Masz pytania lub wątpliwości?',
            description: 'Większość odpowiedzi znajdziesz w sekcji',
            link: {
                text: 'najczęstsze pytania.',
                url: '/faq'
            },
            contactSection: [
                {
                    iconName: 'call',
                    text: [
                        '+48 795 321 321'
                    ],
                    props: {
                        href: 'tel:+48795321321'
                    }
                },
                {
                    iconName: 'clock',
                    text: [
                        'Pon. - pt. 08:00-20:00',
                        'Sb. 09:00-17:00'
                    ]
                }
            ]
        }
    },
    olxin: {
        questionSection: {
            heading: 'Do you have any doubt?',
            description: 'Check the answers in our',
            link: {
                text: 'FAQ',
                url: '/faq'
            },
            contactSection: [
                {
                    iconName: 'call',
                    text: [
                        '9821110000'
                    ],
                    props: {
                        href: 'tel:+919821110000',
                        tracking: {
                            event: 'item_tap_call',
                            chosen_option: 'footer_phone'
                        }
                    }
                },
                {
                    iconName: 'clock',
                    text: [
                        '09:30 AM - 06:30 PM'
                    ]
                },
                {
                    iconName: 'email',
                    text: [
                        'support-in@olxautos.com'
                    ],
                    props: {
                        href: 'mailto:support-in@olxautos.com'
                    }
                }
            ]
        }
    },
    olxar: {
        questionSection: {
            heading: '¿Tenés alguna duda?',
            description: 'Consultá nuestra sección de preguntas frecuentes',
            link: {
                text: 'preguntas frecuentes.',
                url: '/preguntas-frecuentes'
            },
            contactSection: [
                {
                    iconName: 'call',
                    text: [
                        '0800-122-1144'
                    ],
                    props: {
                        href: 'tel:0800-122-1144'
                    }
                },
                {
                    iconName: 'whatsapp',
                    text: [
                        'Comprá 11 2640-5695'
                    ],
                    props: {
                        href: 'https://wa.me/5491126405695'
                    }
                },
                {
                    iconName: 'whatsapp',
                    text: [
                        'Vendé 11 2155-1514'
                    ],
                    props: {
                        href: 'https://wa.me/5491121551514'
                    }
                },
                {
                    iconName: 'clock',
                    text: [
                        'Lunes a Viernes de 08 a 20 hs',
                        'Sábados, Domingos y Feriados de 09 a 20 hs'
                    ]
                }
            ],
            secondaryContactSection: [
                {
                    iconName: 'email',
                    text: [
                        'Comprá ventasargentina@olxautos.com'
                    ],
                    props: {
                        href: 'mailto:ventasargentina@olxautos.com'
                    }
                },
                {
                    iconName: 'email',
                    text: [
                        'Vendé contacto@olxautos.com'
                    ],
                    props: {
                        href: 'mailto:contacto@olxautos.com'
                    }
                }
            ]
        },
        disclaimerSection: {
            heading: 'Descargo de responsabilidad:',
            description:
                'Las marcas y logotipos utilizados son marcas registradas que no son propiedad de AMERICAS CAR GROUP SAU, son propiedad de los respectivos dueños registrados ante las autoridades competentes. El uso de las marcas y logotipos en la presente plataforma es con fines únicamente informativos y en ningún caso de promoción.'
        }
    }
};
