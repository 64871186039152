import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'panamera-react-ui';
import withConfig from 'HOCs/withConfig/withConfig';
import { THEMES } from 'Constants/bundles';

export const ThematicCheckbox = ({
    config,
    ...rest
}) => {
    const theme = config.get('theme', 'id');
    const roundedCorners = theme === THEMES.LETGO || rest.roundedCorners || false;

    return <Checkbox theme={ theme } { ...rest } roundedCorners={ roundedCorners } />;
};

ThematicCheckbox.propTypes = {
    config: PropTypes.shape({
        get: PropTypes.func.isRequired
    })
};

export default withConfig(ThematicCheckbox);
