// helper function which determines whether to show the Lamudi branding (banner & logo) or not
// returns true when market is ID and category is either 'Property' or any of its sub-category

export const showLamudiBranding = (config, categoryID) => {
    if (config.get('lamudiBranding') && config.get('siteCode') === 'olxid'
        && ['88', '4827', '4833', '5154', '5156', '5158', '5160'].includes(categoryID)) {
        return true;
    }

    return false;
};

export const showLamudiDismissibleBanner = (config, categoryID) => {
    if (showLamudiBranding(config, categoryID) && config.get('lamudiBranding', 'showDismissibleBanner')) {
        return true;
    }

    return false;
};
