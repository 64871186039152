import React from 'react';
import css from './TooltipDownloadSection.scss';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import PropTypes from 'prop-types';
import { Link } from 'panamera-react-ui';
import { FormattedMessage as Translation, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { fileNames } from 'Constants/imageUrls';
import { urlPromoNews } from 'Constants/newsAndPromo';

const TooltipDownloadSection = props => {
    const { onMouseEnter, onMouseLeave, isOlxMobbi } = props;

    return (
        <div className={ css.container } onMouseEnter={ onMouseEnter } onMouseLeave={ onMouseLeave } data-aut-id="download-tooltip">
            <div>
                {isOlxMobbi
                    ? <ImageWrapper fileName={ fileNames.OLXMOBBI_QR_CODE } className={ css.qrCodeWrapper } />
                    : <ImageWrapper fileName={ fileNames.OLX_QR_CODE } className={ css.qrCodeWrapper } />
                }
            </div>
            <div className={ css.appSection }>
                <div data-aut-id="scan-code-text">
                    <Translation id="scanQrAppText" defaultMessage="Pindai QR ini atau unduh aplikasi dari :" />
                </div>
                <div className={ css.appImage }>
                    <Link
                        to={ isOlxMobbi ? urlPromoNews.appsLinkCtx : urlPromoNews.appsLinkCla }
                        className={ css.link }
                        data-aut-id="app-store-download"
                    >
                        <ImageWrapper
                            fileName={ fileNames.APPSTORE_ID }
                            className={ css.appLinkImage }
                        />
                    </Link>
                    <Link
                        to={ isOlxMobbi ? urlPromoNews.playstoreLinkCtx : urlPromoNews.playstoreLinkCla }
                        className={ css.link }
                        data-aut-id="play-store-download"
                    >
                        <ImageWrapper
                            fileName={ fileNames.PLAYSTORE_ID }
                            className={ css.appLinkImage }
                        />
                    </Link>
                </div>

            </div>
        </div>
    );
};

TooltipDownloadSection.propTypes = {
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    isOlxMobbi: PropTypes.bool
};

export default compose(injectIntl)(TooltipDownloadSection);
