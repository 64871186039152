/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { computeImageSourceType } from 'Helpers/images';
import ReactObserver from 'Components/ReactObserver/ReactObserver';
import css from './Image.scss';

export class LazyImage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showImage: false,
            loaded: false

        };
    }

    onLoadHandler = () => {
        this.setState({
            loaded: true
        });
    }

    renderSources = () => {
        const { sourceConfig } = this.props;
        const types = Object.keys(sourceConfig);

        return types.map((type, index) => {
            return <source key={ index } type={ computeImageSourceType(type) } srcSet={ sourceConfig[type] } />;
        });
    }

    render() {
        const { showImage, loaded } = this.state;
        const { url, className, alt, sourceConfig, ...rest } = this.props;

        if (showImage) {
            return (
                <picture>
                    { this.renderSources() }
                    <img
                        src={ url }
                        alt={ alt }
                        className={ classnames({ [css.imageStyle]: !loaded, [className]: className }) }
                        onLoad={ this.onLoadHandler }
                        { ...rest }
                    />
                </picture>

            );
        }

        return (
            <ReactObserver triggerOnce="true">
                {({ inView, ref }) => {
                    if (inView) {
                        this.setState({ showImage: true });
                    }
                    return (
                        <span ref={ ref } className={ classnames(css.imageStyle, { [className]: className }) }> loading... </span>
                    );
                }}
            </ReactObserver>
        );
    }
}

LazyImage.propTypes = {
    url: PropTypes.string.isRequired,
    sourceConfig: PropTypes.object,
    className: PropTypes.string,
    alt: PropTypes.string.isRequired
};

LazyImage.defaultProps = {
    alt: '',
    className: '',
    sourceConfig: {}
};

export default LazyImage;
