import { urls } from 'Reducers/items';
import { paramsToQueryString } from 'Helpers/url';
import { isEmpty } from 'Helpers/objects';
import { getLoggedUser } from 'Selectors/user';
import { getSessionLong } from 'Helpers/userSession';
import { selectedLocationSelector } from 'Selectors/location';
import { PLATFORM } from 'Constants/device.APP_TARGET';
import { bundleStatus } from 'Selectors/bundles';

const BXP_REQUIRED_HEADER = { 'x-panamera-client-id': `web-${PLATFORM}` };

const sortBundlesParams = params => {
    return Object.keys(params)
        .sort()
        .reduce((prev, curr) => ({ ...prev, [curr]: params[curr] }), {});
};

const getBundlesParams = (state, reqProps) => {
    const platform = `web-${PLATFORM}`;
    const user = getLoggedUser(state);
    const sessionLongByCookie = reqProps.cookies && getSessionLong(reqProps.cookies.onap || reqProps.cookies['onap_dev']);
    const location = selectedLocationSelector(state);
    const params = {};

    params.platform = platform;
    if (user && user.id) {
        params.userId = user.id;
    }
    if (sessionLongByCookie) {
        params.sessionId = sessionLongByCookie;
    }
    if (location && location.latitude && location.longitude && location.id) {
        params.locationLatitude = location.latitude;
        params.locationLongitude = location.longitude;
        params.locationId = location.id;
    }

    if (Object.entries(params).length > 0) {
        return JSON.stringify(params);
    }
    return null;
};

const getBundlesUrl = (state, url, params = {}) => {
    if (state.api && state.api.queryParam && state.api.queryParam.enableMultiLang) {
        params.lang = state.api.queryParam.lang;
    }

    const strParams = paramsToQueryString(params);

    return strParams ? `${url}?${strParams}` : url;
};

const getBundlesData = (params = {}) => (dispatch, getState, { get }) => {
    const state = getState();
    const sortedBundlesParams = sortBundlesParams(params);
    const url = params && !isEmpty(params) ? getBundlesUrl(state, urls.bundles, sortedBundlesParams) : getBundlesUrl(state, urls.bundles);
    const bundleState = bundleStatus(state);
    const isFetching = bundleState.isFetching;
    const updtBundles = bundleState.updateBundles;
    const hasData = bundleState.lastCollectionUrl === url;
    const hasFailed = bundleState.isError;

    if ((!isFetching && !hasData && !hasFailed) || updtBundles) {
        return dispatch(get(url, 'BUNDLES', null, BXP_REQUIRED_HEADER)).then(res => {
            if (res && res.ok) {
                return Promise.resolve(res.data);
            }
            return Promise.reject(res);
        });
    }

    return Promise.resolve();
};

const getBundles = reqProps => (dispatch, getState) => {
    const state = getState();
    const url = getBundlesParams(state, reqProps);

    if (url) {
        return dispatch(getBundlesData(JSON.parse(url)));
    }
    return Promise.resolve();
};

const updateBundles = value => dispatch => dispatch({ type: 'UPDATE_BUNDLES', value });

export {
    getBundles,
    updateBundles,
    getBundlesData,
    getBundlesParams
};
