/* window */
import React from 'react';
import PropTypes from 'prop-types';
import css from './PreNotification.APP_TARGET.scss';
import { css as uiCss, Heading } from 'panamera-react-ui';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withVariants } from 'LaquesisEOL/withVariants';
import { preNotificationAvailable, preNotificationCookie } from 'Actions/preNotification';
import { FormattedMessage as Translation } from 'react-intl';
import * as permissionsHelper from 'Helpers/pushNotification';
import withTrack from 'HOCs/withTrack/withTrack';
import { setPreNotificationCookie } from './PreNotification';
import classNames from 'classnames';
import { removeGcmSubscription } from 'Helpers/pushNotification';

const { icons } = uiCss;

export class PreNotification extends React.Component {
    static propTypes = {
        track: PropTypes.func.isRequired,
        actionPreNotificationAvailable: PropTypes.func,
        actionPreNotificationCookie: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.preNotificationTitle = <Translation id="preNotificationDesktopTitle" />;
        this.preNotificationLink = <Translation id="preNotificationDesktopLink" />;
        this.preNotificationText = <Translation id="preNotificationDesktopText" />;
        this.state = {
            cursor: css.cursorAuto
        };
    }

    accept = () => {
        const { track } = this.props;

        permissionsHelper.askForPermissionToReceiveNotifications(track)
            .then(this.close());
    };

    close = () => {
        const { actionPreNotificationAvailable, actionPreNotificationCookie } = this.props;

        actionPreNotificationAvailable(false);
        setPreNotificationCookie();
        actionPreNotificationCookie(true);
        removeGcmSubscription();
    };

    handleHover = () => {
        this.setState({ cursor: css.cursorPointer });
    };

    render() {
        const { cursor } = this.state;

        return (
            <div className={ css.preNotificationContainer }>
                <div className={ css.text }>
                    <Heading color="white" fontWeight="normal" type="body1">{ this.preNotificationTitle } <u className={ cursor } onClick={ this.accept } onMouseOver={ this.handleHover }>{ this.preNotificationLink }</u> { this.preNotificationText }</Heading>
                </div>
                <div className={ css.x } onClick={ this.close }>
                    <span className={ classNames(css.fill, icons.panameraIcons, icons['icon-Cross'], cursor) } onMouseOver={ this.handleHover } />
                </div>
            </div>
        );
    }
}

export const mapDispatchToProps = ({
    actionPreNotificationAvailable: preNotificationAvailable,
    actionPreNotificationCookie: preNotificationCookie
});

export default withVariants('PAN-29215', {
    b: compose(
        withTrack,
        connect(null, mapDispatchToProps)
    )(PreNotification),
    'else': () => {
        return null;
    }
});
