import React from 'react';
import PropTypes from 'prop-types';
import { withExperiment } from 'LaquesisEOL/withExperiment';

const DefaultComponent = () => null;

export const withVariants = (experiment, variants) => {
    const WithVariants = props => {
        const Component = variants[props.variant] || variants.else || DefaultComponent;

        return <Component { ...props } />;
    };

    WithVariants.displayName = `withVariants(${ experiment })`;
    WithVariants.propTypes = {
        variant: PropTypes.string
    };

    return withExperiment(experiment)(WithVariants);
};

export default withVariants;
