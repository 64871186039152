import React, { useCallback, useMemo } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import HeroSearch from '../../HeroSearch/HeroSearch';
import { connect } from 'react-redux';
import { setOrigin } from 'Actions/track';
import ActionButtons from './../components/ActionButtons/ActionButtons';
import MultiLanguagePicker from './../components/MultiLanguagePicker/MultiLanguagePicker';
import withTrack from 'HOCs/withTrack/withTrack';
import css from './DefaultHeader.desktop.scss';
import cssLamudi from 'Components/LamudiBranding/LamudiBranding.scss';
import classNames from 'classnames';
import { showLamudiBranding } from 'Helpers/lamudiHelper';
import LamudiBranding from 'Components/LamudiBranding/LamudiBranding';
import { LAMUDI_LOGO } from 'Constants/imageUrls';
import NewIconButton from 'Components/ThematicNewIconButton/ThematicNewIconButton';
import { ICONS, ICON_COLORS } from 'Constants/items';
import withRouter from 'HOCs/withRouter';
import { EXPERIMENT_AD_POSTING } from '../../../../app/pages/Posting/constants';
import withVwo from 'HOCs/withVwo/withVwo';
import { getVariantData } from 'Helpers/vwoTrackParam';

export const DefaultHeaderContent = ({
    renderLogo,
    searchTerm,
    params,
    isOnListing,
    hideSearchBar,
    loginCallback,
    hideActionButtons,
    config,
    isMeetingPage,
    showCompareIcon,
    hideMultiLang,
    router,
    track,
    trackerOrigins,
    setOriginTrack,
    categoryID,
    flowStep,
    experimentVariant,
    vwoClientInstance
}) => {
    const multiLangFlag = config.get('enableMultiLang');
    const isMXCL = config.get('olxAutos', 'isMXCL');
    const carCategory = config.get('adpvAuto', 'carCategory');
    const logoRedirectionUrl = config.get('lamudiBranding', 'logoRedirectionUrl');
    const paramProps = useMemo(() => {
        return isMXCL ? { ...params, categoryID: carCategory } : params;
    }, [isMXCL, carCategory, params]);

    const trackActionButtonClick = useCallback(buttonName => {
        const origin = trackerOrigins.HEADER;
        let eventName = '';

        setOriginTrack(origin);
        switch (buttonName) {
            case 'chat':
                eventName = 'chat_inbox';
                break;
            case 'sell':
                eventName = 'posting_tap_post';
                break;
        }

        track(eventName, {
            origin,
            select_from: 'header',
            ...getVariantData(vwoClientInstance, EXPERIMENT_AD_POSTING)
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setOriginTrack, track, trackerOrigins.HEADER]);

    function onClose() {
        router.goBack();
    }

    return (
        <React.Fragment>
            <div className={ css.headerFA }>
                {experimentVariant && <NewIconButton
                    onClick={ onClose }
                    icon={ ICONS.BACK }
                    color={ ICON_COLORS.PRIMARY_BASE }
                    size={ 20 }
                />}
                { !experimentVariant && renderLogo() }
            </div>

            { showLamudiBranding(config, categoryID)
                && (<LamudiBranding assetUrl={ LAMUDI_LOGO } redirectionUrl={ logoRedirectionUrl } cssClass={ cssLamudi.headerLogo } />)}

            <div className={ classNames(css.search, { [css.mxclSearch]: isMXCL }) }>
                {
                    !hideSearchBar && <HeroSearch
                        size="small"
                        searchTerm={ searchTerm }
                        params={ paramProps }
                        isOnListing={ isOnListing }
                        isMeetingPage={ isMeetingPage }
                    />
                }
            </div>
            { !hideMultiLang && multiLangFlag && <MultiLanguagePicker /> }
            { !hideActionButtons
                && <ActionButtons
                    className={ css.actionButtons }
                    loginCallback={ loginCallback }
                    onClickTrack={ trackActionButtonClick }
                    showCompareIcon={ showCompareIcon }
                    flowStep={ flowStep }
                />
            }
        </React.Fragment>
    );
};

DefaultHeaderContent.propTypes = {
    renderLogo: PropTypes.func.isRequired,
    searchTerm: PropTypes.string,
    isOnListing: PropTypes.bool,
    params: PropTypes.object,
    hideSearchBar: PropTypes.bool,
    hideActionButtons: PropTypes.bool,
    router: PropTypes.shape({
        goBack: PropTypes.func
    }),
    experimentVariant: PropTypes.bool,
    isMeetingPage: PropTypes.bool,
    showCompareIcon: PropTypes.bool,
    loginCallback: PropTypes.func,
    config: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired,
    hideMultiLang: PropTypes.bool,
    track: PropTypes.func.isRequired,
    trackerOrigins: PropTypes.object.isRequired,
    setOriginTrack: PropTypes.func.isRequired,
    categoryID: PropTypes.string,
    flowStep: PropTypes.string,
    vwoClientInstance: PropTypes.shape({
        activate: PropTypes.func.isRequired
    }).isRequired
};

DefaultHeaderContent.defaultProps = {
    showUnreadChat: false,
    hideSearchBar: false,
    isMeetingPage: false,
    showCompareIcon: false,
    hideActionButtons: false,
    loginCallback: () => {},
    hideMultiLang: false
};

export const mapStateToProps = ({ toggleUnreadChats }) => ({
    showUnreadChat: toggleUnreadChats
});

export const mapDispatchToProps = dispatch => ({
    setOriginTrack: origin => dispatch(setOrigin(origin))
});

export default compose(
    withTrack,
    withRouter,
    withVwo,
    connect(mapStateToProps, mapDispatchToProps)
)(DefaultHeaderContent);
