import React from 'react';
import PropTypes from 'prop-types';
import withConfig from 'HOCs/withConfig/withConfig';
import { fileNames } from 'Constants/imageUrls';

const getLogoUrl = config => {
    const baseUrl = config.get('staticAssets');
    const theme = config.get('theme', 'id');

    if (theme === 'otoplus') {
        return `${ baseUrl }${fileNames.OTOPLUS_DESKTOP_LOGO}`;
    }

    if (theme === '321sprzedane') {
        return `${ baseUrl }${fileNames.LOGO_321SPRZEDANE}`;
    }

    return `${ baseUrl }${fileNames.OLX_AUTOS_TEAL_LOGO}`;
};

export const LogoSection = ({ config }) => {
    const logoUrl = getLogoUrl(config);
    const logoAltText = config.get('logoAltText') ?? '';

    // TODO: Alt text is missing for the logo.
    // The alt text should probably be the name of the brand
    return (
        <section>
            <img data-aut-id="footer-logo" src={ logoUrl } alt={ logoAltText } />
        </section>
    );
};

LogoSection.propTypes = {
    config: PropTypes.shape({
        get: PropTypes.func.isRequired
    })
};

export default withConfig(LogoSection);
