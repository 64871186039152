import React from 'react';
import { FormattedMessage as Translation } from 'react-intl';
import classnames from 'classnames';
import PropTypes from 'prop-types';
// COMPONENTS
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
// CSS
import css from './EmptyState.APP_TARGET.scss';

export const EmptyStateNew = ({
    textId,
    iconURL
}) => {
    return (
        <div
            className={ classnames(css.emptyState, css.updateEmptyState) }
            data-aut-id="emptyState"
        >
            <div className={ css.image }>
                <ImageWrapper
                    fileName={ iconURL }
                    className={ css.noNotification }
                />
            </div>
            <p className={ classnames(css.title, css.updateTitle) }>
                <Translation id={ textId } />
            </p>
        </div>
    );
};

EmptyStateNew.propTypes = {
    textId: PropTypes.string.isRequired,
    iconURL: PropTypes.string
};

export default EmptyStateNew;
