import React from 'react';
import PropTypes from 'prop-types';
import css from '../Footer.scss';
import classNames from 'classnames';
import { disclaimerData } from '../../../olxautos/constants/disclaimer';
import withConfig from 'HOCs/withConfig/withConfig';
import Icon from 'Components/ThematicIcon/ThematicIcon';
import { Link } from 'react-router-dom';
import { THEMES } from 'Constants/bundles';

import withTrack from 'HOCs/withTrack/withTrack';
import { OLXAUTOS } from 'Constants/tracking';

const getContactSection = (sectionName, contactSection, trackContactEvent) => contactSection.map(({ iconName, text, props: { href, tracking } = {}}, i) => (
    <div key={ `${sectionName}-step-${i}` } className={ css.step }>
        <Icon size={ 24 } icon={ iconName } />
        <div>
            {
                text && text.map((item, index) => (
                    <div className={ css.iconText } key={ `${sectionName}-icon-${index}` } onClick={ trackContactEvent(tracking) }>
                        {href ? <a title={ item } href={ href } >{item}</a> : item}
                    </div>
                ))
            }
        </div>
    </div>
));

export const DisclaimerDesktop = ({ config, isMxCl, track }) => {
    const theme = config.get('theme', 'id');

    if (theme === THEMES.LETGO) {
        return null;
    }
    const siteCode = config.get('siteCode');
    const showFooterDisclaimer = config.get('showFooterDisclaimer');
    const data = disclaimerData[siteCode];
    const { questionSection = {}, disclaimerSection } = data || {};
    const { contactSection, secondaryContactSection, heading, link } = questionSection;

    function handleLinkClick() {
        track(OLXAUTOS.OLXAUTOS_LINKS_TAPPED, { select_from: heading, chosen_option: link?.text });
    }

    function trackContactEvent({ event, chosen_option } = {}) {
        return function trackContact() {
            if (event) {
                track(event, { select_from: heading, chosen_option });
            }
        };
    }

    const shouldBeFooterDisclaimerRendered = showFooterDisclaimer && !!data;

    return shouldBeFooterDisclaimerRendered ? (
        <section
            className={ classNames(css.topFooter, css.diclaimerFooter, `${isMxCl ? css.withOlxLogo : css.noOlxLogo}`) }
            data-aut-id="disclaimer-desktop-footer"
        >
            {questionSection && (<section className={ css.disclaimerSection } data-aut-id="question-section">
                {questionSection.heading && <div className={ css.heading }>{questionSection.heading}</div>}
                {questionSection.description && <div className={ css.description }>
                    {questionSection.description}
                    {questionSection.link && <Link
                        to={ questionSection.link.url }
                        onClick={ handleLinkClick }
                    >
                        {questionSection.link.text}
                    </Link>}
                </div>}
            </section>)}
            <section
                className={ classNames(css.disclaimerSection, css.contactSection) }
                data-aut-id="primary-contact-section"
            >
                {getContactSection('contact', contactSection, trackContactEvent)}
            </section>
            { secondaryContactSection ? (
                <section
                    className={ classNames(css.disclaimerSection, css.contactSection) }
                    data-aut-id="secondary-contact-section"
                >
                    {getContactSection('secondary-contact', secondaryContactSection, trackContactEvent)}
                </section>
            ) : null }
            {disclaimerSection && (<section
                data-aut-id="disclamer-section"
                className={ classNames(css.disclaimerSection, {
                    [css.negativeMargin]: siteCode === 'olxcl'
                }) }>
                <div className={ css.heading }>{disclaimerSection.heading}</div>
                <div className={ classNames(css.description, css.disclaimerDesc) }>
                    {disclaimerSection.description}
                </div>
            </section>)}
        </section>) : null;
};

DisclaimerDesktop.propTypes = {
    config: PropTypes.object,
    isMxCl: PropTypes.bool,
    track: PropTypes.func.isRequired
};

export default withTrack(withConfig(React.memo(DisclaimerDesktop)));
