import React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { DirectionContextConsumer } from 'Components/DirectionProvider/DirectionContext';

export const withDirection = Component => {
    const ComponentWithDirection = props => (
        <DirectionContextConsumer>
            {
                context => <Component { ...{ direction: context.direction } } { ...props } />
            }
        </DirectionContextConsumer>
    );

    ComponentWithDirection.displayName = `withDirection(${Component.displayName || Component.name})`;

    return hoistNonReactStatic(ComponentWithDirection, Component);
};

export default withDirection;
