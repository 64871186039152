import React, { useState } from 'react';
import css from './TopNewsAndPromoBar.scss';
import { Link } from 'panamera-react-ui';
import TooltipDownloadSection from './component/TooltipDownloadSection';
import withConfig from 'HOCs/withConfig/withConfig';
import PropTypes from 'prop-types';
import { FormattedMessage as Translation, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { fileNames } from 'Constants/imageUrls';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import { urlPromoNews } from 'Constants/newsAndPromo';

const menuItems = [
    { key: 'promo', id: 'btn-promo', name: <Translation id="promo" defaultMessage="Promo" />, toCla: urlPromoNews.promoUrlCla, toCtx: urlPromoNews.promoUrlCtx, className: css.promoButton, icon: fileNames.PROMO_ICON, iconActive: fileNames.PROMO_ICON_ACTIVE },
    { key: 'news', id: 'btn-news', name: <Translation id="news" defaultMessage="News" />, toCla: urlPromoNews.newsUrlCla, toCtx: urlPromoNews.newsUrlCtx, className: css.newsButton, icon: fileNames.NEWS_ICON, iconActive: fileNames.NEWS_ICON_ACTIVE }
];

const TopNewsPromoBar = props => {
    const { config } = props;
    const [hover, setHover] = useState(false);
    const [menuHover, setMenuHover] = useState(null);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const isOlxMobbi = config.get('isOlxMobbi');

    const handleMouseEnter = () => {
        setHover(true);
        setTooltipVisible(true);
    };

    const handleMouseLeave = () => {
        setHover(false);
        setTooltipVisible(false);
    };

    const tooltipMouseEnter = () => {
        setTooltipVisible(true);
    };

    const tooltipMouseLeave = () => {
        setTooltipVisible(false);
    };

    const handleMenuMouseEnter = key => () => {
        setMenuHover(key);
    };

    const handleMenuMouseLeave = () => () => {
        setMenuHover(null);
    };

    return (
        <div className={ css.topNavbarContainer }>
            <div className={ css.contentContainer } data-aut-id="new-header-section">
                <div
                    role="button"
                    tabIndex="0"
                    className={ css.downloadButton }
                    onMouseEnter={ handleMouseEnter }
                    onMouseLeave={ handleMouseLeave }
                    data-aut-id="download-olx-app"
                >
                    <div className={ css.iconWrappper }>
                        <ImageWrapper fileName={ hover ? fileNames.MOBILE_ICON_ACTIVE : fileNames.MOBILE_ICON } />
                    </div>
                    <span className={ css.textNav } data-aut-id="download-app-text">
                        <Translation id="downloadAppFromOlx" defaultMessage="Download OLX App" />
                    </span>
                </div>
                {tooltipVisible && (
                    <TooltipDownloadSection
                        onMouseEnter={ tooltipMouseEnter }
                        onMouseLeave={ tooltipMouseLeave }
                        isOlxMobbi={ isOlxMobbi }
                    />
                )}
                <div className={ css.rightSection }>
                    {menuItems.map(item => (
                        <Link
                            key={ item.key }
                            to={ isOlxMobbi ? item.toCtx : item.toCla }
                            className={ item.className }
                            data-aut-id={ item.id }
                        >
                            <div
                                className={ css.navMenu }
                                onMouseEnter={ handleMenuMouseEnter(item.key) }
                                onMouseLeave={ handleMenuMouseLeave() }>
                                <div className={ css.iconWrappper }>
                                    <ImageWrapper fileName={ menuHover === item.key ? item.iconActive : item.icon } className={ css.icon } />
                                </div>
                                <span className={ css.textNav }>{item.name}</span>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

TopNewsPromoBar.propTypes = {
    config: PropTypes.shape({
        get: PropTypes.func.isRequired
    })
};

export default compose(withConfig, injectIntl)(TopNewsPromoBar);
