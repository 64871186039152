export const ICON_OLX_LOGO_SIZE = 48;
export const ICON_OLX_AUTOS_LOGO_SIZE = 32;

export const DESKTOP_OLX_LOGO_SIZES = { WIDTH: 180, HEIGHT: 45 };
export const DESKTOP_AUTOS_LOGO_SIZES = { WIDTH: 180, HEIGHT: 32 };
export const MOBILE_OLX_LOGO_SIZES = { WIDTH: 120, HEIGHT: 28 };
export const MOBILE_AUTOS_LOGO_SIZES = { WIDTH: 100, HEIGHT: 21 };

export const LOCATION_ICON_SIZE = {
    TWENTY: 20,
    EIGHTTEEN: 18,
    SIXTEEN: 16
};
