import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import { FormattedMessage as Translation } from 'react-intl';
import css from './ActionButtons.scss';
import { Link } from 'panamera-react-ui';
import { withFeatureFlag as withLQFeatureFlag } from 'LaquesisEOL/withFeatureFlag';
import NewIconButton from 'Components/ThematicNewIconButton/ThematicNewIconButton';
import Icon from 'Components/ThematicIcon/ThematicIcon';

import Button from 'Components/Button/Button';
import withRouter from 'HOCs/withRouter';
import { injectIntl } from 'react-intl';
import withConfig from 'HOCs/withConfig/withConfig';
import withTrack from 'HOCs/withTrack/withTrack';
import { getUserImage } from 'Helpers/images';
import NotificationHub from 'Components/NotificationHub/NotificationHub';
import AvatarDropdown from 'Components/AvatarDropdown/AvatarDropdown';
import setLoginModalState from 'Actions/setLoginModalState';
import {
    getLoggedUser,
    userIsLogged
} from 'Selectors/user';
import withDirection from 'HOCs/withDirection/withDirection';
import SellButton from 'Components/SellButton/SellButton';
import { getUserDataSelector } from 'Selectors/user';
import { THEMES } from 'Constants/bundles';
import { OLXAUTOS } from 'Constants/tracking';
import withFeatureFlags from 'HOCs/withFeatureFlags/withFeatureFlags';
import CompareIconCTA from 'Components/Compare/CompareIconCTA/CompareIconCTA';
import FavouriteCTA from 'Components/FavouriteCTA/FavouriteCTA';
import { FAVOURITE, LQ_SHORTLIST_FEATURE } from 'Constants/items';

export class ActionButtons extends React.PureComponent {
  static propTypes = {
      showUnreadChat: PropTypes.bool,
      setLoginModalState: PropTypes.func.isRequired,
      config: PropTypes.shape({
          get: PropTypes.func.isRequired
      }).isRequired,
      type: PropTypes.oneOf(['dark', 'light']),
      loggedUser: PropTypes.object.isRequired,
      _userIsLogged: PropTypes.bool.isRequired,
      className: PropTypes.string,
      router: PropTypes.object.isRequired,
      loginCallback: PropTypes.func,
      direction: PropTypes.oneOf(['ltr', 'rtl']).isRequired,
      onClickTrack: PropTypes.func,
      user: PropTypes.object,
      track: PropTypes.func.isRequired,
      intl: PropTypes.shape({
          formatMessage: PropTypes.func.isRequired
      }).isRequired,
      isTransactionInbox: PropTypes.bool,
      showCompareIcon: PropTypes.bool,
      flowStep: PropTypes.string,
      featureFlag: PropTypes.string
  };

  static defaultProps = {
      showUnreadChat: false,
      type: 'dark',
      className: '',
      loginCallback: () => { },
      onClickTrack: () => { },
      isTransactionInbox: false
  };

  constructor(props) {
      super(props);
      this.translations = {
          postBtnText: <Translation id="sell" />
      };
      const olxAutos = props.config.get('olxAutos');

      this.isMXCL = olxAutos && olxAutos.isMXCL;
      this.isMyZoneFinanceEnabled = olxAutos && olxAutos.financeMyZoneEnabled;
      this.theme = props.config.get('theme', 'id');
      this.isOtoplus = this.theme === THEMES.OTOPLUS;
  }

  toggleLoginModal = () => {
      this.props.setLoginModalState({ state: true, redirectTo: '', trackProps: {}, callback: this.props.loginCallback });
  };

  onClickPost = e => {
      e.preventDefault();

      this.props.onClickTrack('sell');

      if (this.props._userIsLogged) {
          this.props.router.push({
              pathname: '/post'
          });
      }
      else {
          this.props.setLoginModalState({ state: true, redirectTo: '/post', trackProps: { origin: 'posting' }});
      }
  };

  onClickChat = () => {
      this.props.onClickTrack('chat');
  }

  handleContactButtonClick = () => {
      this.props.track(OLXAUTOS.OLXAUTOS_LINKS_TAPPED, { select_from: 'header', chosen_option: this.props.intl.formatMessage({ id: 'contactUs' }) });
  }

  renderContactUsButton = () => {
      const olxAutos = this.props.config.get('olxAutos') || {};
      const contactUsUrl = olxAutos.contactUsPath;

      return (
          <Link
              key="contactUs"
              to={ `/${contactUsUrl}` }
              className={ classNames(css.mxclButtons) }
              data-aut-id="btnContactUs"
              onClick={ this.handleContactButtonClick }
          >
              <Translation id="contactUs" />
          </Link>
      );
  }

  render() {
      const { loggedUser, _userIsLogged, showUnreadChat, config, direction, user, showCompareIcon, flowStep, featureFlag } = this.props;
      const actionButtons = [];
      const staticAssetUrl = config.get('staticAssets');
      const disableUserLogin = config.get('disableUserLogin') || false;
      const logUser = user || loggedUser;
      const contactPhoneNumber = config.get('contactPhoneNumber');
      const showFavCTA = featureFlag === LQ_SHORTLIST_FEATURE && flowStep && _userIsLogged;

      if (this.isMXCL) {
          if (!disableUserLogin) {
              actionButtons.push(
                  this.renderContactUsButton()
              );
              if (showFavCTA) {
                  actionButtons.push(
                      <FavouriteCTA
                          key={ FAVOURITE }
                          direction={ direction }
                          classname={ css.favCTA }
                          isLoggedIn={ _userIsLogged }
                          flowStep={ flowStep }
                      />
                  );
              }

              if (showCompareIcon && flowStep) {
                  actionButtons.push(
                      <CompareIconCTA
                          key="compareIcon"
                          showInHeader={ true }
                          className={ css.compareIcon }
                          flowStep={ flowStep }
                      />
                  );
              }
          }
          else if (contactPhoneNumber) {
              actionButtons.push(
                  <div className={ css.phoneNumber } key="disableUserLogin" data-aut-id="disableUserLogin">
                      <Icon
                          icon="call"
                          size={ 20 }
                          color="primary"
                      />
                      <span className={ classNames(css.mxclButtons, css.phoneNumberAndIcon) }>{contactPhoneNumber}</span>
                  </div>
              );

              actionButtons.push(
                  this.renderContactUsButton()
              );
          }
          if (_userIsLogged) {
              if (this.props.isTransactionInbox) {
                  actionButtons.push(
                      <Link
                          key="chat"
                          to="/olxmobbi/nf/chat"
                          className={ classNames(css.icons,
                              { [css.notification]: showUnreadChat },
                              this.props.isTransactionInbox ? css.transactionNotification : css.classifiedNotification) }
                          rel="nofollow"
                          data-aut-id="btnChat"
                          onClick={ this.onClickChat }
                      >
                          <NewIconButton
                              name="chat"
                              icon="chatNavigation"
                              direction={ direction }
                          />
                      </Link>
                  );
              }
              actionButtons.push(
                  <div
                      className={ css.avatarContainer }
                      key="avatarDropdown"
                  >
                      <AvatarDropdown
                          imageURl={ getUserImage(staticAssetUrl, logUser) }
                          userName={ loggedUser.name }
                          isMxCl={ this.isMXCL }
                          isMyZoneFinanceEnabled={ this.isMyZoneFinanceEnabled }
                          isOtoplus={ this.isOtoplus }
                      />
                  </div>
              );
          }
          else if (!disableUserLogin) {
              actionButtons.push(
                  <Button
                      key="login"
                      type="primaryBtn"
                      data-aut-id="btnLogin"
                      onClick={ this.toggleLoginModal }
                      className={ classNames(css.buttons, css.loginBtnMxCl) }
                  >
                      <Translation id="logIn" />
                  </Button>
              );
          }
      }
      else {
          if (_userIsLogged) {
              actionButtons.push(
                  <Link
                      key="chat"
                      to="/nf/chat"
                      className={ classNames(css.icons, { [css.notification]: showUnreadChat }, css.classifiedNotification) }
                      rel="nofollow"
                      data-aut-id="btnChat"
                      onClick={ this.onClickChat }
                  >
                      <NewIconButton
                          name="chat"
                          icon="chatNavigation"
                          direction={ direction }
                      />
                  </Link>
              );
              if (config.get('notificationHub').enabled) {
                  actionButtons.push(
                      <div
                          key="notificationHub"
                          className={ css.icons }
                          data-aut-id="notificationHub"
                      >
                          <NotificationHub />
                      </div>
                  );
              }
              actionButtons.push(

                  <div
                      className={ css.avatarContainer }
                      key="avatarDropdown"
                  >
                      <AvatarDropdown
                          imageURl={ getUserImage(staticAssetUrl, logUser) }
                          userName={ loggedUser.name }
                      />
                  </div>
              );
          }
          else if (!disableUserLogin) {
              actionButtons.push(
                  <Button
                      key="login"
                      type="tertiaryBtn"
                      data-aut-id="btnLogin"
                      onClick={ this.toggleLoginModal }
                      className={ classNames(css.buttons, css.loginBtn) }
                  >
                      <Translation id="logIn" />
                  </Button>
              );
          }

          actionButtons.push(
              <SellButton
                  key="postBtn"
                  href="/post"
                  data-aut-id="btnSell"
                  onClick={ this.onClickPost }
                  label={ this.translations.postBtnText }
                  theme={ this.theme }
              />
          );
      }

      return (
          <div
              className={ classNames(css.actionButtons,
                  { [css.mxclActionsButtons]: this.isMXCL },
                  this.props.className,
                  css[this.props.type]
              ) }
              data-aut-id="actionButtons"
          >
              {actionButtons}
          </div>
      );
  }
}

export const mapStateToProps = state => {
    return {
        user: getUserDataSelector(state),
        showUnreadChat: state.toggleUnreadChats,
        loggedUser: getLoggedUser(state),
        _userIsLogged: !!userIsLogged(state)
    };
};

export const mapDispatchToProps = dispatch => {
    return {
        setLoginModalState: state => dispatch(setLoginModalState(state))
    };
};

export default compose(
    withRouter,
    withConfig,
    withTrack,
    withDirection,
    withFeatureFlags,
    withLQFeatureFlag(LQ_SHORTLIST_FEATURE),
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(ActionButtons);
