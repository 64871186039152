import React from 'react';
import css from './Search.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Search as UiSearch } from 'panamera-react-ui';
import { FormattedMessage as Translation, injectIntl } from 'react-intl';
import withRouter from 'HOCs/withRouter';
import debounce from 'lodash/debounce';
import { compose } from 'redux';
import withTrack from 'HOCs/withTrack/withTrack';
import withConfig from 'HOCs/withConfig/withConfig';
import { getSearchSuggestionsSelector, getAutocompleteRequestMetaData } from 'Selectors/autocomplete';
import { getSearchSuggestions } from 'Actions/items';
import { AUTOCOMPLETE_SUBSTRING, SEARCH_START, SEARCH_TYPE_EVENT } from 'Constants/tracking';
import { RECENT_SEARCH_ICON } from 'Constants/imageUrls';

import { get as getRecentSearches, remove as removeRecentSearches } from 'Helpers/recentSearches';
import { SVG_TYPE } from 'Constants/images';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';

export class Search extends React.Component {
    static propTypes = {
        intl: PropTypes.shape({
            formatMessage: PropTypes.func.isRequired
        }).isRequired,
        config: PropTypes.object,
        track: PropTypes.func.isRequired,
        trackOrigin: PropTypes.string,
        getSearchSuggestions: PropTypes.func.isRequired,
        onEnter: PropTypes.func.isRequired,
        searchSuggestions: PropTypes.array,
        className: PropTypes.string,
        searchTerm: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        formClassName: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        autocompleteRequestMetaData: PropTypes.object
    }

    static defaultProps = {
        searchTerm: '',
        formClassName: ''
    }

    constructor(props) {
        super(props);

        const olxAutos = props.config.get('olxAutos');

        this.isMXCL = olxAutos && olxAutos.isMXCL;
    }

    componentDidUpdate(prevProps) {
        const { autocompleteRequestMetaData: { xRequestId: prevXRequestId } = {}} = prevProps;
        const { searchSuggestions, searchTerm, config, trackOrigin, autocompleteRequestMetaData = {}} = this.props;
        const autoComplete = config.get('autoComplete');
        const { xRequestId, spell, version } = autocompleteRequestMetaData;
        const dyncamicAutoSuggest = spell && version ? `${spell?.substring(0, AUTOCOMPLETE_SUBSTRING)}:${version}` : 'FAIL_VERSION';

        if (autoComplete.enabled
            && searchTerm.length
            && searchSuggestions !== prevProps.searchSuggestions
            && xRequestId !== prevXRequestId
        ) {
            this.props.track(SEARCH_TYPE_EVENT, {
                origin: trackOrigin,
                autocomplete_suggestions_count: searchSuggestions.length,
                search_user_query: searchTerm,
                autocomplete_version: this.isMXCL ? config.get('autocompleteVersion') : dyncamicAutoSuggest,
                search_autocomplete_suggestions: searchSuggestions,
                'x-request-id': xRequestId,
                spell_key: spell,
                spell_version: version
            });
        }
    }

    componentWillUnmount = () => {
        this.handleGetSuggestions.cancel();
    }

    translations = {
        didYouMean: <Translation id="didYouMean" />
    };

    handleChangeValue = input => {
        const autoComplete = this.props.config.get('autoComplete');

        this.props.onChange(input);

        if (autoComplete.enabled && input.trim().length) {
            this.handleGetSuggestions();
        }
    };

    getSearchParams() {
        const autoComplete = this.props.config.get('autoComplete');

        return {
            q: this.props.searchTerm,
            site_code: autoComplete.siteCode
        };
    }

    handleGetSuggestions = debounce(
        () => {
            const searchParams = this.getSearchParams();

            if (searchParams.q.length) {
                this.props.getSearchSuggestions(searchParams);
            }
        },
        this.props.config.get('searchSuggestionsRetrivalWaitTime')
    );

    handleOnFocus = () => {
        const { trackOrigin } = this.props;

        this.props.track(SEARCH_START, {
            origin: trackOrigin,
            resultset_type: trackOrigin
        });

        if (this.props.searchTerm.length) {
            this.handleGetSuggestions();
        }
    };

    handleSearch = (suggestion = {}, recentSearch, recentSearchIndex) => {
        const { searchSuggestions = [], config, autocompleteRequestMetaData = {}} = this.props;
        const { xRequestId, spell, version } = autocompleteRequestMetaData;
        const dyncamicAutoSuggest = spell && version ? `${spell?.substring(0, AUTOCOMPLETE_SUBSTRING)}:${version}` : 'FAIL_VERSION';

        if (typeof suggestion === 'string') {
            return this.props.onEnter(suggestion, searchSuggestions);
        }

        const category = {
            id: suggestion.subcategory_id || suggestion.category_id,
            name: suggestion.subtitle || ''
        };
        const title = suggestion.title || recentSearch;

        return this.props.onClick({
            category,
            title,
            suggestions: searchSuggestions,
            recent_search_selected: recentSearch,
            recentSearch_selected_position: recentSearchIndex + 1,
            autocomplete_suggestions_count: searchSuggestions.length,
            autocomplete_selected_position: searchSuggestions.indexOf(suggestion),
            autocomplete_version: this.isMXCL ? config.get('autocompleteVersion') : dyncamicAutoSuggest,
            'x-request-id': xRequestId,
            spell_key: spell,
            spell_version: version
        });
    };

    render() {
        const {
            intl,
            className,
            formClassName,
            searchTerm,
            searchSuggestions: suggestionsList
        } = this.props;
        const defaultPlaceholder = this.isMXCL ? intl.formatMessage({ id: 'searchCar' }) : intl.formatMessage({ id: 'searchHint' });
        const recentSearchTitle = intl.formatMessage({ id: 'recentSearches' });
        const placeholder = searchTerm || defaultPlaceholder;
        const recentSearchIcon = (<ImageWrapper
            fileName={ RECENT_SEARCH_ICON }
            types={ SVG_TYPE }
            height={ '15.51' }
            width={ '14.77' }
        />);

        return (
            <div className={ className || css.search }>
                <UiSearch
                    className={ formClassName }
                    placeholder={ placeholder }
                    handler={ this.handleSearch }
                    getSuggestions={ this.handleChangeValue }
                    suggestions={ suggestionsList }
                    getRecentSearches={ getRecentSearches }
                    recentSearchIcon={ recentSearchIcon }
                    removeRecentSearches={ removeRecentSearches }
                    recentSearchTitle={ recentSearchTitle }
                    isAutosSearch={ this.isMXCL }
                    submitButtonText=""
                    resetButtonText=""
                    value={ searchTerm }
                    forceLowercase={ false }
                    onFocus={ this.handleOnFocus }
                    icon="icon-arrow-top-left"
                    hasFocus={ false }
                    translations={ this.translations }
                />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    trackOrigin: state.track.origin,
    searchSuggestions: getSearchSuggestionsSelector(state, props),
    autocompleteRequestMetaData: getAutocompleteRequestMetaData(state, props)
});

const mapDispatchToProps = ({
    getSearchSuggestions
});

export default compose(
    withRouter,
    withTrack,
    withConfig,
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(Search);
