import lazyLoad from 'Components/asyncLoading/lazyLoad';

const AsyncDownloadApp = lazyLoad({
    loader: () => import(/* webpackChunkName: "download-app" */'Components/DownloadApp/DownloadApp'),
    options: {
        rootMargin: '800px'
    }
});

export default AsyncDownloadApp;
