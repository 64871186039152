import { getSelectedLangCode } from './config';
import * as urlHelper from 'Helpers/url';

export const getURLParams = (state, params = {}) => {
    const langParam = getSelectedLangCode(state);

    if (langParam) {
        params.lang = langParam;
    }

    return urlHelper.paramsToQueryString(params);
};
