// @TODO Replace this line when a fix is available
// PR: https://github.com/w3c/IntersectionObserver/pull/279
// Doc: https://github.com/w3c/IntersectionObserver/tree/master/polyfill#browser-support
if (typeof window !== 'undefined') {
    require('intersection-observer');
}
// React implementation of the Intersection Observer API to tell you when an element enters or leaves the viewport.
import ReactObserver from 'react-intersection-observer';

export default ReactObserver;
