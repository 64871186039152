import React from 'react';
import { FormattedMessage as Translation } from 'react-intl';
import css from './EmptyState.APP_TARGET.scss';
import { fileNames } from 'Constants/imageUrls';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import { WEBP_TYPE } from 'Constants/images';

export const EmptyState = () => {
    return (
        <div
            className={ css.emptyState }
            data-aut-id="emptyState"
        >
            <div className={ css.title }>
                <Translation id="noNotificationsTitle" />
            </div>
            <p className={ css.text }>
                <Translation id="noNotificationsText" />
            </p>
            <div className={ css.image }>
                <ImageWrapper
                    fileName={ fileNames.NOTIFICATIONS }
                    types={ WEBP_TYPE }
                />
            </div>
        </div>
    );
};

export default EmptyState;
