/* global window */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import css from './MultiLanguagePicker.scss';
import NewIconButton from 'Components/ThematicNewIconButton/ThematicNewIconButton';

import OutsideClickListener from 'Components/OutsideClickListener/OutsideClickListener';
import { withConfig } from 'HOCs/withConfig/withConfig';
import withTrack from 'HOCs/withTrack/withTrack';
import { setOrigin } from 'Actions/track';
import classNames from 'classnames';
import cookieManagerHelper from 'Helpers/cookies';
import withRouter from 'HOCs/withRouter';
import { getSelectedLang } from 'Selectors/getLanguages';
import LanguageList from '../LanguageList/LanguageList';

export class MultiLanguagePicker extends React.PureComponent {
    static propTypes = {
        marketConfig: PropTypes.shape({
            get: PropTypes.func.isRequired
        }).isRequired,
        selectedLang: PropTypes.string.isRequired,
        track: PropTypes.func.isRequired,
        trackerOrigins: PropTypes.object.isRequired,
        setOriginTrack: PropTypes.func.isRequired
    }

    static defaultProps = {
        className: ''
    };

    constructor(props) {
        super(props);

        this.langSupp = props.marketConfig.get('langs');
        this.isPPCMigration = props.marketConfig.get('ppcMigration');

        this.state = {
            isDropdownOpen: false,
            selectLang: props.selectedLang
        };
    }

    renderLanguageList = () => {
        const { isDropdownOpen, selectLang } = this.state;

        return (
            <LanguageList
                className={ classNames(css.dropdownItems, { [css.show]: isDropdownOpen }) }
                onSelect={ this.onSelect }
                selectLang={ selectLang }
                langSupp={ this.langSupp }
            />
        );
    }

    closeDropdown = () => this.setState({ isDropdownOpen: false });

    onSelect = lang => {
        this.setState({ selectLang: lang });
        const origin = this.props.trackerOrigins.HEADER;
        const trackingParam = {
            origin,
            chosen_option: lang
        };

        this.props.setOriginTrack(origin);
        this.props.track('language_updated', trackingParam);

        cookieManagerHelper.createCookie('lang', lang);
        if (typeof window !== 'undefined') {
            window.location.reload();
        }
        this.closeDropdown();
    }

    openDropdown = () => {
        return this.setState(state => ({ isDropdownOpen: !state.isDropdownOpen }), () => {
            this.props.track('language_update_show');
        });
    };

    render() {
        const langObj = this.langSupp.find(({ param }) => param === this.state.selectLang);
        const languageName = langObj && langObj.name || '';

        return (
            <OutsideClickListener onOutsideClick={ this.closeDropdown } className={ css.container }>
                <div className={ css.dropdownList }>
                    <div onClick={ this.openDropdown } className={ classNames(css.allLanguages, { [css.hideLang]: this.isPPCMigration }) } data-aut-id="allLanguages">
                        <span className={ css.title }>{ languageName }</span>
                        <span className={ classNames(css.dropdownChevron, this.state.isDropdownOpen ? css.dropdownChevronUp : '') } >
                            <NewIconButton
                                icon="arrowDown"
                                animation={ false }
                            />
                        </span>
                    </div>
                </div>
                <div className={ classNames(css.dropdown) } data-aut-id="categoryList">
                    { this.renderLanguageList() }
                </div>
            </OutsideClickListener>
        );
    }
}

export const mapDispatchToProps = dispatch => {
    return {
        setOriginTrack: origin => dispatch(setOrigin(origin))
    };
};

export const mapStateToProps = (state, ownProps) => {
    return {
        selectedLang: getSelectedLang(state, ownProps)
    };
};

export default compose(
    withConfig('marketConfig'),
    withTrack,
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(MultiLanguagePicker);
